<template>
  <div class="home">
    <div v-show="showPdf == true">
      <!-- invoivce -->
      <div id="pdfportion">
        <div style="font-family: 'Acme', 'Lobster', 'Open Sans', sans-serif; font-size: 30px; font-style: italic; text-align: center; justify-content: center; padding-top: 10px; vertical-align: bottom">
          <div>
            <img :src="logo" style="max-width: 50px; left: 0" />
          </div>
          <div style="vertical-align: bottom; margin-top: -2vh">
            {{ company.company_name }}
          </div>
        </div>
        <div style="display: flex; justify-content: center">
          <div>
            <strong> </strong>
          </div>
        </div>
        <div style="display: flex; justify-content: center">
          <div>
            <!-- 123/5, BCS Cmputer City, IDB Bhaban -->
            {{ company.company_address }}
          </div>
        </div>
        <div style="display: flex; justify-content: center">Hotline: {{ company.contact_person_phone }}</div>
        <div class="columns">
          <div class="column is-12">
            <div style="float: left; margin-left: 10px; font-size: 18px">
              <p style="font-weight: 700">Date: {{ calcDate }}</p>
            </div>
            <div style="float: right; margin-right: 10px; font-size: 18px">
              <p style="font-weight: 700">Time: {{ calcTime }}</p>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-12" style="border-top: 1px solid #dbdbdb; border-bottom: 1px solid #dbdbdb">
            <div style="justify-content: left; float: left; margin-left: 10px; font-size: 18px; font-weight: 600">
              <p style="">Name: {{ custInfo.gender }} {{ custInfo.first_name }} {{ custInfo.last_name }}</p>
              <p>Phone: {{ custInfo.phone }}</p>
            </div>
            <div style="justify-content: left; float: right; margin-right: 10px; font-size: 18px; font-weight: 600">
              <p>Card No: {{ cardData.card_no }}</p>
            </div>
          </div>
        </div>

        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Item</th>
              <th>Date</th>
              <th>Qnty</th>
              <th>Cost</th>
              <th>Discount/ S. Discount</th>
              <th>Final Trans</th>
            </tr>
          </thead>
          <tbody style="font-size: 15px">
            <tr v-for="bill in billData" :key="bill.id">
              <template v-if="bill.bill_item == 'Room Clearance'">
                <td>Payment ({{ bill.trx_type }})</td>
              </template>
              <template v-else>
                <td>{{ bill.bill_item }}</td>
              </template>
              <td>{{ bill.date }}</td>
              <template v-if="bill.bill_category == 'debit'">
                <td>{{ bill.item_quantity }}</td>
              </template>
              <template v-else>
                <td>N.A</td>
              </template>
              <template v-if="bill.bill_category == 'debit' && bill.type_bill == 'room'">
                <td>{{ (bill.cost / bill.item_quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
              </template>
              <template v-else-if="bill.bill_category == 'debit' && bill.type_bill == 'laundry'">
                <td>{{ bill.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
              </template>
              <template v-else-if="bill.bill_category == 'debit' && bill.type_bill == 'room_service'">
                <td>{{ bill.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
              </template>
              <template v-else>
                <td>{{ bill.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
              </template>
              <template v-if="bill.bill_category == 'debit'">
                <td>{{ bill.discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} (0%)</td>
              </template>
              <template v-else>
                <td>{{ bill.special_discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
              </template>
              <template v-if="bill.bill_category == 'debit'">
                <td>{{ ((bill.cost / bill.item_quantity) * bill.item_quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
              </template>
              <template v-else>
                <td>(-){{ bill.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
              </template>
            </tr>
          </tbody>
        </table>
        <hr style="margin-top: -20px" />

        <div style="text-align: right; font-size: 18px; margin-right: 10px">Sub Total: {{ (parseInt(roomCost) + laundryCalculated + roomServiceCalculated).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>

        <div style="text-align: right; font-size: 18px; margin-right: 10px">VAT({{ companyInfo.tax }}%): {{ (roomCost * (companyInfo.tax / 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>

        <div style="text-align: right; font-size: 18px; margin-right: 10px">Service Charge({{ companyInfo.service_charge }}%): {{ (roomCost * (companyInfo.service_charge / 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>

        <template v-if="laundryVatAmount != 0 || laundryServiceCharge != 0">
          <div style="text-align: right; font-size: 18px; margin-right: 10px">Laundry VAT & SD({{ laundryVatAmount }}% | {{ laundryServiceCharge }}%): {{ laundryCalculatedDuty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>
        </template>
        <template v-if="roomServiceVat != 0 || roomServiceServiceCharge != 0">
          <div style="text-align: right; font-size: 18px; margin-right: 10px">RoomService VAT & SD({{ roomServiceVat }}%|{{ roomServiceServiceCharge }}%): {{ roomServiceCalculatedDuty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>
        </template>

        <div style="text-align: right; font-size: 18px; margin-right: 10px">Discount(s):(-) {{ resInfo[0].previous_discounts.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>

        <hr />
        <p style="text-align: right; margin-right: 10px; margin-top: -20px; font-size: 18px">Grand Total: {{ (laundryCalculated + roomServiceCalculated + laundryCalculatedDuty + roomServiceCalculatedDuty + roomCost + roomCost * (companyInfo.tax / 100) + roomCost * (companyInfo.service_charge / 100) - resInfo[0].previous_discounts).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>
        <br />
        <p style="text-align: right; margin-right: 10px; margin-top: -20px; font-size: 18px">Advance & Payment(s): (-) {{ (advanceCalculated + roomClearanceCalculated).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>

        <template v-if="resInfo[0].total_due != 0">
          <hr />
          <p style="text-align: right; margin-right: 10px; margin-top: -20px; font-size: 18px">
            Total Due(s):
            {{
              (laundryCalculated + roomServiceCalculated + laundryCalculatedDuty + roomServiceCalculatedDuty + roomCost + roomCost * (companyInfo.tax / 100) + roomCost * (companyInfo.service_charge / 100) - resInfo[0].previous_discounts - advanceCalculated - roomClearanceCalculated)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }}
            BDT
          </p>
        </template>
        <template v-else>
          <hr />
          <p style="text-align: right; margin-right: 10px; margin-top: -20px; font-weight: bold; font-size: 18px; color: red">PAID</p>
        </template>
      </div>
    </div>
    <div class="" style="height: 5px"></div>

    <div v-if="statusR == true">
      <div class="modal is-active">
        <div class="modal-background" @click.prevent="statusR = false"></div>
        <div class="modal-content" style="height: 52vh; background: white">
          <div style="margin-top: 20px">
            <p class="subtitle is-4">Call bellboy for All Rooms assigned to primary account <template v-if="resInfo[0].secondary_room != ''"></template></p>

            <div class="box" style="height: 60px; background-color: #f6f7fb; border: 1px solid #d3d8ed">
              <div style="margin-top: -10px; display: flex; margin-top: 2px; justify-content: center">
                <p style="font-weight: bold"></p>
                <ul v-for="room in roomNosFlat" :key="room.id">
                  <li class="button" style="width: 80px; height: 40px; margin-top: -15px; color: white; background-color: #0c9eda">{{ room }}</li>
                </ul>
              </div>
            </div>
            <div class="field is-horizontal" style="margin-top: 10px">
              <div class="field-label is-normal" style="">
                <label class="label" style="font-size: 18px; text-align: left; font-weight: normal; margin-left: 10px">Special Instruction:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control" style="width: 410px">
                    <textarea class="textarea" placeholder="Instruction" v-model="specialIns" style="width: 200px"></textarea>
                  </p>
                </div>
              </div>
            </div>
            <div style="margin-top: 25px">
              <button
                class="button is-info is-light"
                @click.prevent="
                  bellboySubmit();
                  statusR = false;
                "
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- delete this -->

    <div class="columns" style="height: 52vh">
      <div class="column is-9" style="">
        <!-- <div class="box ml-3" style="height:100%; border: 1px solid #cfd8e8;" > -->
        <div class="box ml-3" style="background-repeat: no-repeat; background-size: cover; height: 100%; border: 1px solid #cfd8e8">
          <div v-if="this.firstName != ''">
            <div class="columns" style="height: 38vh">
              <div class="column is-3" style="">
                <template v-if="cusPic.length != 0">
                  <img :src="cusPic.pic" alt="blank person" style="max-width: 200px; max-height: 210px; float: left; border: 1px solid black; margin-top: 1vh" />
                </template>
                <template v-else>
                  <img src="../assets/blankperson.jpeg" alt="blank person" style="max-width: 190px; max-height: 210px; float: left; border: 1px solid black; margin-top: 1vh" />
                </template>
              </div>
              <div class="column is-5" style="">
                <div style="text-align: left">
                  <p style="border-bottom: 1px solid #cfd8e6; font-size: 20px">Primary guest</p>
                  <p style="font-size: 22px; font-weight: bold">Name: {{ custInfo.gender }} {{ custInfo.first_name }} {{ custInfo.last_name }}</p>
                  <p style="font-size: 21px; margin-top: 5px">Phone No.:{{ custInfo.phone }}</p>
                  <div class="columns" style="margin-top: 5px; max-width: 500px">
                    <div class="column is-3">
                      <p style="font-size: 20px">Address:</p>
                    </div>
                    <div class="column is-7">
                      <p style="font-size: 18px">{{ custInfo.address }}</p>
                    </div>
                  </div>

                  <p style="font-size: 20px">
                    Country:<template v-if="custInfo.country != null && custInfo.country != ''">{{ custInfo.country }}</template
                    ><template v-else> N.A</template>
                  </p>
                </div>
              </div>
              <div class="column is-4" style="text-align: left; overflow-y: auto">
                <template v-if="secondModal == true">
                  <p style="font-size: 20px; margin-top: 0px; border-bottom: 1px solid #cfd8e6">Room No: {{ resInfo[0].room }}</p>
                </template>
                <template v-else>
                  <p style="font-size: 20px; margin-top: 0px; border-bottom: 1px solid #cfd8e6">Room No: {{ secondaryRoomPoint.room_no }}</p>
                </template>

                <!-- <template v-if="resInfo[0].primary_room_no == '' || resInfo[0].primary_room_no == null"> -->
                <template v-if="secondModal == true">
                  <!-- editroom -->
                  <p style="font-size: 20px; margin-top: 5px">Card No: {{ cardData.card_no }}</p>
                  <p style="font-size: 20px; margin-top: 5px">Secondary Rooms: {{ resInfo[0].secondary_room }}</p>
                  <div style="display: flex">
                    <p style="font-size: 20px">
                      Date: <span style="font-size: 18px">{{ resInfo[0].check_in_date }} / {{ resInfo[0].check_out_date }}</span>
                    </p>
                  </div>

                  <p style="font-size: 20px; margin-top: 5px">Number of Occupance: {{ resInfo[0].no_guest }}</p>

                  <p style="font-size: 20px; margin-top: 5px">Occupancy Duration: {{ resInfo[0].No_days }} days</p>
                  <p style="font-size: 20px; margin-top: 5px">
                    Current Dues:
                    {{
                      parseInt(resInfo[0].total_due)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }}
                    BDT
                  </p>
                  <p style="font-size: 20px; margin-top: 5px">
                    Total Credited:
                    {{
                      parseInt(resInfo[0].total_credit)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }}
                    BDT
                  </p>
                </template>
                <template v-else>
                  <p style="font-size: 20px; margin-top: 5px; font-weight: 600">Primary Room: {{ resInfo[0].room }}</p>
                  <p style="font-size: 20px; margin-top: 5px; font-weight: 200">(click on primary room to view more info)</p>
                </template>
              </div>
            </div>

            <div class="columns" style="margin-top: 2.5vh">
              <!-- edited here -->
              <div class="column is-12">
                <!-- <div v-if="resInfo[0].primary_room_no == '' || resInfo[0].primary_room_no == null"> -->
                <div v-if="secondModal == true">
                  <div class="columns" style="border-top: 2px solid #dfe6e9; max-height: 10vh">
                    <div class="column is-2">
                      <div class="tooltip">
                        <span class="tooltiptext">Modify Guest Info</span>
                        <span @click="(iconModal = 'modInfo'), (rejectModal = true), (modalClose = true)" style="cursor: pointer">
                          <i class="fas fa-solid fa-pen" style="color: #add8e6; font-size: clamp(12px, 25px, 25px)"></i>
                        </span>

                        <!-- <img src="../assets/modify.png" alt="human" style="width:25px;" @click.prevent="iconModal = 'modInfo', rejectModal = true, modalClose= true"> -->
                      </div>
                    </div>
                    <div class="column is-2">
                      <div class="tooltip">
                        <span class="tooltiptext">CheckOut</span>

                        <router-link :to="{ name: 'checkout', params: { id: resInfo[0].id, fromId: 'home' }, props: { fromId: resInfo[0].room } }" style="color: white"><i class="fas fa-solid fa-door-open" style="font-size: 25px; color: #b84d4d"></i></router-link>
                      </div>
                    </div>
                    <div class="column is-2">
                      <div class="tooltip">
                        <span class="tooltiptext">Urgent Clean Room</span>
                        <!-- <img src="../assets/transfer.png" alt="human" style="width:25px;" @click.prevent="iconModal = 'transfer', rejectModal = true, modalClose= true"> -->
                        <span @click.prevent="urgentClean()" style="cursor: pointer"><i class="fas fa-solid fa-broom" style="font-size: 25px; color: #49ddc7"></i></span>
                      </div>
                    </div>
                    <div class="column is-2">
                      <div class="tooltip">
                        <span class="tooltiptext">Modify Check In</span>
                        <!-- <img src="../assets/checkin.png" alt="human" style="width:30px;" @click.prevent="iconModal = 'modCheckin', rejectModal = true, modalClose= true"> -->
                        <span
                          @click.prevent="
                            (iconModal = 'modCheckin'), (rejectModal = true), (modalClose = true);
                            getPrimarySecondaryRooms();
                            roomTransferModal = false;
                          "
                          style="cursor: pointer"
                          ><i class="fas fa-solid fa-credit-card" style="font-size: 23px; color: #66b2ff"></i
                        ></span>
                      </div>
                    </div>
                    <div class="column is-2">
                      <div class="tooltip">
                        <span class="tooltiptext">Dues</span>
                        <!-- <img src="../assets/dues.png" alt="human" style="width:25px; " @click.prevent="iconModal = 'dues', rejectModal = true, modalClose= true,getBills(),showPayment = false"> -->
                        <span @click.prevent="(iconModal = 'dues'), (rejectModal = true), (modalClose = true), getBills(), (showPayment = false)" style="cursor: pointer"><i class="fas fa-solid fa-money-bill" style="font-size: 25px; color: #118c4f"></i></span>
                        <!-- due edit-->
                        <!-- rejectModal = true, modalClose= true -->
                      </div>
                    </div>
                    <div class="column is-2">
                      <div class="tooltip">
                        <span class="tooltiptext">Bellboy</span>
                        <!-- <img src="../assets/bellboy.png" alt="human" style="width:25px; " @click.prevent="statusR = true"> -->
                        <span @click.prevent="statusR = true" style="cursor: pointer"><i class="fas fa-solid fa-bell" style="font-size: 25px; color: #f6b000"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <div v-else>
            <p style="font-size: 25px; font-weight: 500; margin-top: 100px">Please select a room to view information</p>
          </div>

          <!-- <div style="height:30px; float: left;">
            
            <span class="tooltip">
               <img src="../assets/modify.png" alt="human" style="width:25px;" @click.prevent="iconModal = 'modInfo', rejectModal = true, modalClose= true">
               <span class="tooltiptext">Modify Info</span>
            </span>
            <span class="tooltip">
              <img src="../assets/checkout.png" alt="human" style="width:25px; margin-left:140px;" @click.prevent="iconModal = 'checkout', rejectModal = true, modalClose= true">
              <span class="tooltiptext">Checkout</span>
            </span>
            <span data-tooltip="Delux Room">
              <img   src="../assets/transfer.png" alt="human" style="width:25px; margin-left:140px;" @click.prevent="iconModal = 'transfer', rejectModal = true, modalClose= true">
              <span></span>
            </span>
            <span class="tooltip">
               <img src="../assets/checkin.png" alt="human" style="width:30px; margin-left:140px;" @click.prevent="iconModal = 'modCheckin', rejectModal = true, modalClose= true">
               <span class="tooltiptext">Modify CheckIn</span>
            </span>
           <span class="tooltip">
             <img src="../assets/dues.png" alt="human" style="width:25px; margin-left:140px;" @click.prevent="iconModal = 'dues', rejectModal = true, modalClose= true">
             <span class="tooltiptext">Dues</span>
           </span >
           <span class="tooltip">
              <img src="../assets/bellboy.png" alt="human" style="width:25px; margin-left:140px;" @click.prevent="iconModal = 'bellboy', rejectModal = true, modalClose= true">
              <span class="tooltiptext">Bellboy</span>
           </span>
          </div> -->
        </div>
      </div>
      <div class="column is-3" style="">
        <div class="box" style="background-repeat: no-repeat; background-size: cover; height: 100%; margin-left: -10px; width: 99%; border: 1px solid #cfd8e8">
          <!-- <div class="box" style="height:100%; margin-left:-10px; width:99%; border: 1px solid #cfd8e8;"> -->
          <!--  -->

          <div class="">
            <p class="box" style="height: 10vh; font-size: 16px; font-weight: bold">Current Room Vacancy Status</p>
          </div>
          <table class="table" style="width: 99%; height: 30vh; margin-top: 5px">
            <thead>
              <tr>
                <th>Room</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Occupied</td>
                <td>{{ roomStatus[0] }}</td>
              </tr>
              <tr>
                <td>Available</td>
                <td>{{ roomStatus[1] }}</td>
              </tr>
              <tr>
                <td>Under Maintenance</td>
                <td>{{ roomStatus[2] }}</td>
              </tr>
              <!-- <tr>
                  <td>Urgent</td><td>{{roomStatus[3]}}</td>
                </tr> -->
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="advanceScreen == true">
        <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
          <div class="modal-background" @click.prevent="advanceScreen = false"></div>
          <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 50vw; margin-right: 0">
            <div class="tabs is-centered is-medium">
              <ul>
                <li v-bind:class="{ 'is-active': isA == 'cash' }"><a v-on:click="(isA = 'cash'), (trxType = 'cash')">Cash</a></li>
                <li v-bind:class="{ 'is-active': isA == 'card' }"><a v-on:click="(isA = 'card'), (trxType = 'card')">Card</a></li>
                <li v-bind:class="{ 'is-active': isA == 'bkash' }"><a v-on:click="(isA = 'bkash'), (trxType = 'bkash')">bkash</a></li>
              </ul>
            </div>
            <div class="tab-contents">
              <div class="content" v-bind:class="{ 'is-active': isA == 'cash' }" style="height: 80vh">
                <div style="overflow-y: auto; max-height: 20vh">
                  <table style="overflow-y: scroll">
                    <thead>
                      <tr>
                        <th>Room</th>
                        <th>Type</th>
                        <th>Days</th>
                        <th>Cost</th>
                        <th>Discount</th>
                        <th>Total Cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>{{ tempAdd.room_no }}</th>
                        <th>{{ tempAdd.room_type }}</th>
                        <th>{{ dayCount }}</th>
                        <th>{{ selectedRoomTypeData.cost }}</th>
                        <th>{{ selectedRoomTypeData.discount }}(%)</th>
                        <th>{{ parseInt(selectedRoomTypeData.cost) * dayCount - selectedRoomTypeData.cost * dayCount * selectedRoomTypeData.discount }}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <hr />
                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-size: 18px">Sub Total: {{ roomCost }} BDT</p>
                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-size: 18px">VAT({{ this.companyInfo.tax }}%): {{ roomCost * (this.companyInfo.tax / 100) }} BDT</p>
                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-size: 18px">Service Charge({{ this.companyInfo.service_charge }}%): {{ roomCost * (this.companyInfo.service_charge / 100) }} BDT</p>

                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-size: 18px">Special Discount: {{ amountDiscount }} BDT</p>

                <hr />
                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-weight: bold; font-size: 18px">Grand Total: {{ totalCalculated - amountDiscount }} BDT</p>

                <div style="margin-left: 8vw">
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; width: 180px">Special Discount:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="">
                          <input class="input" type="text" placeholder="Discount Amount" v-model="amountDiscount" style="width: 300px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; width: 180px">Remarks S. Discount:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="">
                          <input class="input" type="text" placeholder="Reason" v-model="specialDiscountReason" style="width: 300px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; width: 180px">Amount:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control">
                          <input class="input" type="text" placeholder="Credit amount" v-model="amount_credited" style="width: 300px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-contents">
              <div class="content" v-bind:class="{ 'is-active': isA == 'card' }" style="height: 80vh">
                <div style="overflow-y: auto; max-height: 20vh">
                  <table style="overflow-y: scroll">
                    <thead>
                      <tr>
                        <th>Room</th>
                        <th>Type</th>
                        <th>Days</th>
                        <th>Cost</th>
                        <th>Discount</th>
                        <th>Total Cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>{{ tempAdd.room_no }}</th>
                        <th>{{ tempAdd.room_type }}</th>
                        <th>{{ dayCount }}</th>
                        <th>{{ selectedRoomTypeData.cost }}</th>
                        <th>{{ selectedRoomTypeData.discount }}(%)</th>
                        <th>{{ parseInt(selectedRoomTypeData.cost) * dayCount - selectedRoomTypeData.cost * dayCount * selectedRoomTypeData.discount }}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <hr />
                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-size: 18px">Sub Total: {{ roomCost }} BDT</p>
                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-size: 18px">VAT({{ this.companyInfo.tax }}%): {{ roomCost * (this.companyInfo.tax / 100) }} BDT</p>
                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-size: 18px">Service Charge({{ this.companyInfo.service_charge }}%): {{ roomCost * (this.companyInfo.service_charge / 100) }} BDT</p>

                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-size: 18px">Special Discount: {{ amountDiscount }} BDT</p>

                <hr />
                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-weight: bold; font-size: 18px">Grand Total: {{ totalCalculated - amountDiscount }} BDT</p>

                <div style="margin-left: 8vw">
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; width: 180px">Special Discount:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="">
                          <input class="input" type="text" placeholder="Discount Amount" v-model="amountDiscount" style="width: 300px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; width: 180px">Remarks S. Discount:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="">
                          <input class="input" type="text" placeholder="Reason" v-model="specialDiscountReason" style="width: 300px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; width: 180px">Amount:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control">
                          <input class="input" type="text" placeholder="Credit amount" v-model="amount_credited" style="width: 300px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; width: 180px">Card no:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control">
                          <input class="input" type="text" placeholder="Card no" maxlength="16" v-model="trans_id" style="width: 300px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; width: 180px">Date of expiry:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control">
                          <input class="input" type="date" placeholder="Discount amount" v-model="doe_card" style="width: 300px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-contents">
              <div class="content" v-bind:class="{ 'is-active': isA == 'bkash' }" style="height: 80vh">
                <div style="overflow-y: auto; max-height: 20vh">
                  <table style="overflow-y: scroll">
                    <thead>
                      <tr>
                        <th>Room</th>
                        <th>Type</th>
                        <th>Days</th>
                        <th>Cost</th>
                        <th>Discount</th>
                        <th>Total Cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>{{ tempAdd.room_no }}</th>
                        <th>{{ tempAdd.room_type }}</th>
                        <th>{{ dayCount }}</th>
                        <th>{{ selectedRoomTypeData.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</th>
                        <th>{{ selectedRoomTypeData.discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}(%)</th>
                        <th>{{ (parseInt(selectedRoomTypeData.cost) * dayCount - selectedRoomTypeData.cost * dayCount * selectedRoomTypeData.discount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <hr />
                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-size: 18px">Sub Total: {{ roomCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>
                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-size: 18px">VAT({{ this.companyInfo.tax }}%): {{ (roomCost * (this.companyInfo.tax / 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>
                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-size: 18px">Service Charge({{ this.companyInfo.service_charge }}%): {{ (roomCost * (this.companyInfo.service_charge / 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>

                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-size: 18px">Special Discount: {{ amountDiscount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>

                <hr />
                <p style="text-align: right; margin-right: 50px; margin-top: -20px; font-weight: bold; font-size: 18px">Grand Total: {{ (totalCalculated - amountDiscount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>
                <div style="margin-left: 8vw">
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; width: 180px">Special Discount:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="">
                          <input class="input" type="text" placeholder="Discount Amount" v-model="amountDiscount" style="width: 300px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; width: 180px">Remarks S. Discount:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="">
                          <input class="input" type="text" placeholder="Reason" v-model="specialDiscountReason" style="width: 300px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; width: 180px">Amount:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control">
                          <input class="input" type="text" placeholder="Credit amount" v-model="amount_credited" style="width: 300px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 18px; width: 180px">Transaction id:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control">
                          <input class="input" type="text" placeholder="Discount amount" maxlength="18" v-model="trans_id" style="width: 300px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-top: 4vh">
              <button
                class="button is-pulled-left"
                style="margin-left: 10px"
                @click.prevent="
                  rejectModal = true;
                  advanceScreen = false;
                "
              >
                back
              </button>

              <button class="button" @click.prevent="advanceScreen = false" style="margin-right: 5px">Cancel</button>

              <template v-if="amount_credited != ''">
                <button class="button" @click.prevent="submitGuestInfo()" style="background-color: #26ade4; color: white">Pay & Submit</button>
              </template>
              <template v-else>
                <button disabled class="button" @click.prevent="submitGuestInfo()" style="background-color: #26ade4; color: white">Pay & Submit</button>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div v-if="rejectModal == true">
        <div class="modal" :class="{ 'is-active': modalClose }" style="align-items: start; flex-direction: row; justify-content: end">
          <div class="modal-background" @click.prevent="rejectModal = false"></div>
          <div class="modal-content" style="background: #f6f7fb; max-height: 100%; width: 700px; margin-right: 0; overflow-x: hidden">
            <div v-if="iconModal == 'addResInput'">
              <div class="" style="height: 100vh">
                <div class="columns" style="height: 45px; background-color: #51627e">
                  <div class="column is-12">
                    <div style="background-color: #51627e">
                      <div style="float: left">
                        <p style="font-size: 25px; color: white; margin-left: 5px; font-weight: 500">
                          Room No: <span style="font-weight: 700">{{ tempAdd.room_no }}</span>
                        </p>
                      </div>

                      <div class="is-pulled-right">
                        <p style="font-size: 25px; color: white; margin-right: 5px; font-weight: 500">
                          Room: <span style="font-weight: 500">{{ roomAddType }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="columns" style="margin-top: -20px; margin-left: 20px">
                  <div class="column is-12">
                    <div style="height: 85vh; float: left; margin-top: 5px; min-height: 35vh">
                      <div style="margin-top: 0px">
                        <div class="columns">
                          <div class="column is-8">
                            <div class="field is-horizontal">
                              <div class="field-label is-normal">
                                <label class="label" style="font-size: 20px; width: 110px; text-align: left">Check In:</label>
                              </div>
                              <div class="field-body">
                                <div class="field">
                                  <p class="control">
                                    <input class="input" type="date" placeholder="date" v-model="checkInDate" :min="checkMinDate()" style="width: 200px; float: left" />
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="field is-horizontal">
                              <div class="field-label is-normal">
                                <label class="label" style="font-size: 20px; width: 110px; text-align: left">Check Out:</label>
                              </div>
                              <div class="field-body">
                                <div class="field">
                                  <p class="control">
                                    <input class="input" type="date" placeholder="date" v-model="checkOutDate" :min="checkFromDate()" style="width: 200px; float: left" />
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="field is-horizontal">
                              <div class="field-label is-normal">
                                <label class="label" style="font-size: 20px; width: 110px; text-align: left">No. Guest:</label>
                              </div>
                              <div class="field-body">
                                <div class="field">
                                  <p class="control">
                                    <input class="input" type="text" placeholder="Number" v-model="guestNum" style="width: 200px; float: left" />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr style="border-top: 2px solid #dbdbdb; margin-top: -10px; margin-bottom: 0px" />
                        <div class="field is-horizontal" style="">
                          <div class="field-label is-normal">
                            <label class="label" style="float: left; color: #c2c2c2">Primary Guest</label>
                          </div>
                        </div>

                        <div class="field is-horizontal">
                          <div class="field-label is-normal" style="">
                            <label class="label" style="font-size: 18px; text-align: left"><span style="color: red">*</span>Phone:</label>
                          </div>
                          <div class="field-body">
                            <div class="field">
                              <p class="control" style="">
                                <!-- <input class="input" type="text" placeholder="Number" v-model="pHone" style="width:250px;float:left;" @keyup.prevent="showBox=true" @keyup.enter="showBox= !showBox"> -->
                                <vue-tel-input
                                  defaultCountry="BD"
                                  v-model="pHone"
                                  mode="international"
                                  @keyup.prevent="
                                    showBox = true;
                                    checkForNew();
                                  "
                                  @keyup.enter="showBox = !showBox"
                                  style="float: left"
                                ></vue-tel-input>
                              </p>
                              <div v-if="showBox == true" class="box" style="width: 260px; height: 100px; overflow-y: scroll">
                                <div v-for="r of resultQuery" :key="r.id" @click.prevent="fillInputs(r.id)">
                                  <div>{{ r.phone }} {{ r.first_name }} {{ r.last_name }}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div>
                          <vue-tel-input v-model="phone" mode="international"></vue-tel-input>
                        </div> -->

                        <div class="field is-horizontal">
                          <div class="field-label is-normal" style="margin-left: -10px">
                            <label class="label" style="font-size: 20px">Name:</label>
                          </div>
                          <div class="select is-fullwidth" style="width: 110px; margin-right: 10px">
                            <select v-model="gSelect">
                              <option value="" hidden><b>Gender</b></option>
                              <option v-for="filter in genderTypes" :key="filter.id">{{ filter }}</option>
                            </select>
                          </div>
                          <div class="field-body">
                            <div class="field">
                              <p class="control">
                                <input class="input" type="text" placeholder="First Name" v-model="fName" />
                              </p>
                            </div>
                            <div class="field">
                              <p class="control">
                                <input class="input" type="text" placeholder="Last Name" v-model="LName" />
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="field is-horizontal">
                          <div class="field-label is-normal" style="margin-left: -40px">
                            <label class="label" style="font-size: 20px">Email:</label>
                          </div>
                          <div class="field-body">
                            <div class="field">
                              <p class="control">
                                <input class="input" type="text" placeholder="Email" v-model="eMail" style="width: 210px; float: left" />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="field is-horizontal">
                          <div class="field-label is-normal" style="margin-left: -13px">
                            <label class="label" style="font-size: 20px">Address:</label>
                          </div>
                          <div class="field-body">
                            <div class="field">
                              <p class="control" style="width: 410px">
                                <textarea class="textarea" placeholder="Address" v-model="aDress" style="width: 200px"></textarea>
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="field is-horizontal">
                          <div class="field-label is-normal">
                            <label class="label" style="font-size: 20px; width: 140px">Early Check In:</label>
                          </div>
                          <div class="field-body">
                            <div class="field">
                              <p class="control">
                                <input class="input" type="time" placeholder="Time" step="3600000" v-model="checkInTime" style="width: 200px; float: left" />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="field is-horizontal">
                          <div class="field-label is-normal">
                            <label class="label" style="font-size: 20px; width: 160px">Early Check Out:</label>
                          </div>
                          <div class="field-body">
                            <div class="field">
                              <p class="control">
                                <input class="input" type="time" placeholder="Time" step="3600000" v-model="checkOutTime" style="width: 200px; float: left" />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="height: 5vh">
                  <button class="button is-pulled-left" @click.prevent="rejectModal = false" style="margin-left: 10px">Cancel</button>

                  <template v-if="checkInDate != '' && checkOutDate != '' && fName != '' && LName != '' && guestNum != '' && pHone != ''">
                    <button class="button is-pulled-right" style="background-color: #26ade4; color: white; margin-right: 20px" @click.prevent="billPayModal()">Next</button>
                  </template>
                  <template v-else>
                    <button disabled class="button is-pulled-right" style="background-color: #26ade4; color: white; margin-right: 20px" @click.prevent="billPayModal()">Next</button>
                  </template>
                </div>
              </div>
            </div>
            <!-- div icon res end -->
            <div v-if="iconModal == 'modInfo'" style="height: 100vh; margin-top: 5px">
              <div class="columns">
                <div class="column is-12">
                  <div style="float: right; margin-bottom: 5px; margin-left: 5px">
                    <button class="button" @click.prevent="showInfo = !showInfo" style="border: 1px solid #099dd9; background-color: white"><i class="fas fa-solid fa-pen" style="color: #099dd9; font-size: 19px"></i></button>
                  </div>
                  <div v-if="showInfo == true" style="float: right; margin-bottom: 5px; margin-left: 5px">
                    <button
                      class="button"
                      style="background-color: #099dd9; color: white"
                      @click.prevent="
                        submitModInfo();
                        (rejectModal = false), (modalClose = false);
                      "
                    >
                      Submit
                    </button>
                  </div>

                  <!-- edited -->
                  <br />

                  <div v-if="showInfo == false">
                    <div style="font-size: 20px; font-weight: 500; margin-left: 10px">
                      <div class="container">
                        <div style="display: flex">
                          <p style="font-size: 25px; font-weight: bold">Name: &nbsp;</p>
                          <p style="font-weight: bold; font-size: 25px">{{ genderSelection }} {{ firstName }} {{ lastName }}</p>
                        </div>

                        <hr style="border: 1px solid black" />
                        <div style="display: flex; margin-bottom: 5px">
                          <p style="font-size: 20px; font-weight: bold">Phone No: &nbsp;</p>
                          <p>{{ phone }}</p>
                        </div>

                        <div style="display: flex; margin-bottom: 5px">
                          <p style="font-size: 20px; font-weight: bold">Email: &nbsp;</p>
                          <p>{{ email }}</p>
                        </div>
                        <div style="display: flex; margin-bottom: 5px">
                          <p style="font-size: 20px; font-weight: bold">Address: &nbsp;</p>
                          <p>{{ address }}</p>
                        </div>
                        <div style="display: flex; margin-bottom: 5px">
                          <p style="font-size: 20px; font-weight: bold">Country: &nbsp;</p>
                          <p>{{ country }}</p>
                        </div>
                        <div style="display: flex; margin-bottom: 5px">
                          <p style="font-size: 20px; font-weight: bold">Nationality: &nbsp;</p>
                          <p>{{ nationality }}</p>
                        </div>
                        <div style="display: flex; margin-bottom: 5px">
                          <p style="font-size: 20px; font-weight: bold">Occupation: &nbsp;</p>
                          <p>{{ occupation }}</p>
                        </div>
                        <hr style="border-top: 1px solid black" />
                        <div style="display: flex; margin-bottom: 5px">
                          <p style="font-size: 20px; font-weight: bold">NID/DL: &nbsp;</p>
                          <p>{{ nidDl }}</p>
                        </div>
                        <div style="display: flex; margin-bottom: 5px">
                          <p style="font-size: 20px; font-weight: bold">Passport No.: &nbsp;</p>
                          <p>{{ passport }}</p>
                        </div>
                        <br />
                        <div class="columns">
                          <div class="columns is-12">
                            <div style="display: flex; margin-left: 23px; margin-bottom: 10px">
                              <div style="display: flex">
                                <p style="font-size: 20px; font-weight: bold">Passport Issue.: &nbsp;</p>
                                <p>{{ doi }}</p>
                              </div>
                              <div style="display: flex; margin-left: 100px">
                                <p style="font-size: 20px; font-weight: bold">Passport Expiry.: &nbsp;</p>
                                <p>{{ dof }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style="display: flex">
                          <p style="font-size: 20px; font-weight: bold; margin-bottom: 5px">Visa Type.: &nbsp;</p>
                          <p>{{ visa }}</p>
                        </div>
                        <br />
                        <div class="columns">
                          <div class="columns is-12">
                            <div style="display: flex; margin-left: 23px">
                              <div style="display: flex">
                                <p style="font-size: 20px; font-weight: bold">Visa Issue.: &nbsp;</p>
                                <p>{{ vdoi }}</p>
                              </div>
                              <div style="display: flex; margin-left: 100px">
                                <p style="font-size: 20px; font-weight: bold">Visa Expiry.: &nbsp;</p>
                                <p>{{ vdof }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="columns">
                          <div class="column is-12">
                            <hr style="border-top: 1px solid black" />
                            <div style="display: flex">
                              <p style="font-size: 20px; font-weight: bold">Secondary Rooms: &nbsp;</p>
                              <p>{{ resInfo.secondary_room }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-else style="margin-left: 5px">
                    <div class="field is-horizontal">
                      <div class="field-label is-normal" style="">
                        <label class="label" style="font-size: 18px; text-align: left">Phone:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control">
                            <input class="input" type="text" placeholder="Phone No." v-model="phone" style="width: 210px; float: left" />
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="field is-horizontal">
                      <div class="field-label is-normal" style="">
                        <label class="label" style="font-size: 18px; text-align: left">Name:</label>
                      </div>
                      <div class="select is-fullwidth" style="width: 110px; margin-right: 10px">
                        <select v-model="genderSelection">
                          <option value="" hidden><b>Gender</b></option>
                          <option v-for="filter in genderTypes" :key="filter.id">{{ filter }}</option>
                        </select>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control">
                            <input class="input" type="text" placeholder="First Name" v-model="firstName" />
                          </p>
                        </div>
                        <div class="field">
                          <p class="control">
                            <input class="input" type="text" placeholder="Last Name" v-model="lastName" />
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="field is-horizontal">
                      <div class="field-label is-normal" style="">
                        <label class="label" style="font-size: 18px; text-align: left">Email:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control">
                            <input class="input" type="text" placeholder="Email" v-model="email" style="width: 210px; float: left" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal" style="">
                        <label class="label" style="font-size: 18px; text-align: left">Address:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="width: 410px">
                            <textarea class="textarea" placeholder="Address" v-model="address" style="width: 200px"></textarea>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal" style="">
                        <label class="label" style="font-size: 18px; text-align: left">NID/DL:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="">
                            <input class="input" type="int" placeholder="Number" v-model="nidDl" style="width: 200px; float: left" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal" style="">
                        <label class="label" style="font-size: 18px; text-align: left">Country:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="">
                            <input class="input" type="text" placeholder="Country Name" v-model="country" style="width: 200px; float: left" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal" style="">
                        <label class="label" style="font-size: 18px; text-align: left">Nationality:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="">
                            <input class="input" type="text" placeholder="Nationality" v-model="nationality" style="width: 200px; float: left" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal" style="">
                        <label class="label" style="font-size: 18px; text-align: left">Occupation:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="">
                            <input class="input" type="int" placeholder="Info" v-model="occupation" style="width: 200px; float: left" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="field is-horizontal">
                      <div class="field-label is-normal" style="">
                        <label class="label has-text-left" style="width: 110px; font-size: 18px">Passport No:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="">
                            <input class="input" type="int" placeholder="Number" v-model="passport" style="width: 200px; float: left" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal" style="">
                        <label class="label" style="font-size: 18px; text-align: left">Passport:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control">
                            <input class="input" type="date" placeholder="Date Of Issue" v-model="doi" />
                          </p>
                        </div>
                        <div class="field">
                          <p style="font-size: 30px; color: #c2c2c2">/</p>
                        </div>
                        <div class="field">
                          <p class="control">
                            <input class="input" type="date" placeholder="Date Of Expiry" v-model="dof" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="field is-horizontal" style="">
                      <div class="field-label is-normal" style="">
                        <label class="label" style="font-size: 18px; width: 85px; text-align: left">Visa Type:</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <p class="control" style="">
                            <input class="input" type="int" placeholder="Type" v-model="visa" style="width: 200px; float: left" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal">
                      <div class="field-label is-normal" style="">
                        <label class="label" style="font-size: 18px; text-align: left; width: 50px">Visa:</label>
                      </div>
                      <div class="field-body" style="margin-left: -50px">
                        <div class="field">
                          <p class="control">
                            <input class="input" type="date" placeholder="Date Of Issue" v-model="vdoi" />
                          </p>
                        </div>
                        <div class="field">
                          <p style="font-size: 30px; color: #c2c2c2">/</p>
                        </div>
                        <div class="field">
                          <p class="control">
                            <input class="input" type="date" placeholder="Date Of Expiry" v-model="vdof" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <input type="file" style="min-width: 70%" label="User Image" autogrow @change="OnFileUpload" />

                    <!-- <div class="file has-name">
                                  <label class="file-label">
                                    <input class="file-input" type="file" name="resume">
                                    <span class="file-cta">
                                      <span class="file-icon">
                                        <i class="fas fa-upload"></i>
                                      </span>
                                      <span class="file-label">
                                        Choose a file…
                                      </span>
                                    </span>
                                    <span class="file-name">
                                      <label for="file-input">click me</label>
                                    </span>
                                  </label>
                                </div> -->

                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="iconModal == 'checkout'" style="height: 100vh">checkout</div>

            <div v-if="iconModal == 'modCheckin'" style="height: 100vh">
              <div class="columns">
                <div class="column is-12">
                  <div class="columns">
                    <div class="column is-12">
                      <div style="float: right; margin-bottom: 5px; margin-left: 5px">
                        <button class="editbutton" @click.prevent="showInfo = !showInfo" style="width: 53px; height: 39px; border-radius: 5px; border: 1px solid #099dd9; background-color: white; margin-right: 5px; margin-top: 1px; cursor: pointer"><i class="fas fa-solid fa-pen" style="color: #099dd9; font-size: 19px"></i></button>
                      </div>
                      <div v-if="showInfo == true" style="float: right; margin-bottom: 5px">
                        <button
                          class="button"
                          @click.prevent="
                            submitResInfo();
                            (rejectModal = false), (modalClose = false);
                          "
                          style="background-color: #099dd9; color: white"
                        >
                          Submit
                        </button>
                      </div>
                      <div v-if="showInfo == true" style="float: left; margin-bottom: 5px; margin-left: 2px">
                        <button
                          class="button"
                          @click.prevent="
                            getPrimarySecondaryRooms();
                            roomTransferModal = !roomTransferModal;
                          "
                          style="background-color: #099dd9; color: white"
                        >
                          Room Transfer
                        </button>
                      </div>
                    </div>
                  </div>

                  <div v-if="showInfo == true">
                    <div v-if="roomTransferModal == true">
                      <!-- Room Transfer -->
                      <p style="display: block; font-weight: 500; font-size: 22px; border-bottom: 1px solid black; margin-bottom: 10px; margin-top: -15px; margin-bottom: 15px">Transfer Room</p>
                      <div v-if="trasnferModal == false">
                        <p style="font-weight: 500; font-size: 20px">Primary Room</p>
                        <table class="table is-fullwidth">
                          <thead>
                            <tr>
                              <th>Room No</th>
                              <th>Type</th>
                              <th>Cost</th>
                              <th>Transfer Room</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{{ resInfo[0].room }}</td>
                              <td>{{ primaryRoomInfo.type_name }}</td>
                              <td>{{ primaryRoomInfo.cost }}</td>
                              <td>
                                <button
                                  class="button"
                                  @click.prevent="
                                    trasnferModal = true;
                                    SearchTransferDate();
                                    tansferPrimary = 'primary';
                                  "
                                  style="background-color: #099dd9; color: white"
                                >
                                  Transfer
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <template v-if="secondaryRoomInfo.length != 0">
                          <p style="font-weight: 500; font-size: 20px">Secondary Room</p>
                          <table class="table is-fullwidth">
                            <thead>
                              <tr>
                                <th>Room No</th>
                                <th>Type</th>
                                <th>Cost</th>
                                <th>Transfer Room</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="sroom in secondaryRoomInfo" :key="sroom.id">
                                <td>{{ sroom.room_no }}</td>
                                <td>{{ sroom.type_name }}</td>
                                <td>{{ sroom.cost }}</td>
                                <td>
                                  <button
                                    class="button"
                                    @click.prevent="
                                      trasnferModal = true;
                                      SearchTransferDate();
                                      tansferPrimary = 'secondary';
                                      secondaryTransfer = sroom.room_no;
                                    "
                                    style="background-color: #099dd9; color: white"
                                  >
                                    Transfer
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </template>
                      </div>
                      <div v-else>
                        <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
                          <div
                            class="modal-background"
                            @click.prevent="
                              trasnferModal = false;
                              TransferRoomSelect = '';
                            "
                          ></div>
                          <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 50vw; margin-right: 0">
                            <div style="display: flex; justify-content: space-between">
                              <div style="align-self: center"><p style="font-size: 20px; font-weight: 500">Rooms available For Transfer</p></div>
                              <button
                                style="margin-right: 10px; margin-top: 5px; background-color: #099dd9; color: white"
                                @click="
                                  trasnferModal = false;
                                  TransferRoomSelect = '';
                                "
                                class="button"
                              >
                                Back
                              </button>
                            </div>

                            <hr />
                            <div v-if="TransferBool != false" style="">
                              <div class="columns">
                                <div class="column is-10">
                                  <div style="display: flex; align-items: center">
                                    <p style="font-size: 20px; margin-left: 10px; color: red; font-weight: 550">Room Selected: {{ TransferRoomSelect.room_no }}</p>
                                    <template v-if="iconCancel == true">
                                      <div class="cancelIcon" data-tooltip="Clear Selected" style="">
                                        <i
                                          class="fas fa-solid fa-xmark"
                                          style="font-size: 30px; margin-left: 20px; margin-top: 0.5vh"
                                          @click.prevent="
                                            TransferRoomSelect = '';
                                            TransferBool = false;
                                          "
                                        ></i>
                                        <!-- <img src="../assets/modify.png" alt="human" style="width:25px;" @click.prevent="iconModal = 'modInfo', rejectModal = true, modalClose= true"> -->
                                      </div>
                                    </template>
                                  </div>
                                </div>
                                <div class="column is-2">
                                  <button style="margin-right: 10px; background-color: #099dd9; color: white" class="button" @click="submitTransferRoom()">Submit</button>
                                </div>
                              </div>
                            </div>
                            <table class="table is-fullwidth">
                              <thead>
                                <tr>
                                  <th>Room No</th>
                                  <th>Type</th>
                                  <th>Cost</th>
                                  <th>Transfer Room</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="sroom in roomVacantDataTemp" :key="sroom.id">
                                  <td>{{ sroom.room_no }}</td>
                                  <td>{{ sroom.type_name }}</td>
                                  <td>{{ sroom.cost }}</td>
                                  <td>
                                    <button
                                      class="button"
                                      style="background-color: #099dd9; color: white"
                                      :disabled="TransferBool == true"
                                      @click.prevent="
                                        TransferRoomSelect = sroom;
                                        TransferBool = true;
                                        iconCancel = true;
                                      "
                                    >
                                      Select
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div style="margin: 0 10px 0 10px">
                        <div class="field is-horizontal">
                          <div class="field-label is-normal">
                            <label class="label" style="font-size: 20px; width: 100px; margin-left: -15px">Check In:</label>
                          </div>
                          <div class="field-body">
                            <div class="field">
                              <p class="control">
                                <input class="input" type="date" placeholder="date" v-model="checkInDate" readonly style="width: 250px; float: left" />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="field is-horizontal">
                          <div class="field-label is-normal">
                            <label class="label" style="font-size: 20px; width: 150px; margin-left: -45px">Check Out:</label>
                          </div>
                          <div class="field-body">
                            <div class="field">
                              <p class="control">
                                <input class="input" type="date" placeholder="date" v-model="checkOutDate" readonly style="width: 250px; float: left" />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="field is-horizontal">
                          <div class="field-label is-normal">
                            <label class="label" style="font-size: 20px">Early_Check_In:</label>
                          </div>
                          <div class="field-body">
                            <div class="field">
                              <p class="control">
                                <input class="input" type="text" placeholder="Early Check In" v-model="earlyCheckIn" style="width: 200px; float: left" />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="field is-horizontal">
                          <div class="field-label is-normal">
                            <label class="label" style="font-size: 20px">Early_Check_Out:</label>
                          </div>
                          <div class="field-body">
                            <div class="field">
                              <p class="control">
                                <input class="input" type="text" placeholder="Early Check Out" v-model="earlyCheckOut" style="width: 200px; float: left" />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="field is-horizontal">
                          <div class="field-label is-normal">
                            <label class="label" style="font-size: 20px; width: 150px">Extra Breakfast:</label>
                          </div>
                          <div class="field-body">
                            <div class="field">
                              <p class="control">
                                <input class="input" type="text" placeholder="No. of extra breakfast" v-model="extraBreakfast" style="width: 200px; float: left" />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="field is-horizontal">
                          <div class="field-label is-normal">
                            <label class="label" style="font-size: 20px; width: 150px">Adults:</label>
                          </div>
                          <div class="field-body">
                            <div class="field">
                              <p class="control">
                                <input class="input" type="text" placeholder="No. of extra breakfast" v-model="AdultsInfo" style="width: 200px; float: left" />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="field is-horizontal">
                          <div class="field-label is-normal">
                            <label class="label" style="font-size: 20px; width: 150px">Childs:</label>
                          </div>
                          <div class="field-body">
                            <div class="field">
                              <p class="control">
                                <input class="input" type="text" placeholder="No. of extra breakfast" v-model="childInfo" style="width: 200px; float: left" />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="field is-horizontal">
                          <div class="field-label is-normal">
                            <label class="label" style="font-size: 20px; width: 150px">No. of Guest:</label>
                          </div>
                          <div class="field-body">
                            <div class="field">
                              <p class="control">
                                <input class="input" type="text" placeholder="No. of Guest" v-model="guestNo" style="width: 200px; float: left" />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div style="margin: 0 10px 0 10px">
                      <div class="columns" style="margin-top: -40px">
                        <div class="column is-6">
                          <div style="float: left">
                            <p style="font-size: 20px">Room Type: {{ roomDataB.room_type }}</p>
                          </div>
                        </div>
                        <div class="column is-6">
                          <div style="float: right">
                            <p style="font-size: 20px">Res. Status: {{ resInfo[0].status }}</p>
                          </div>
                        </div>
                      </div>

                      <div class="columns">
                        <div class="columns is-12">
                          <div style="display: flex; margin-left: 10px; margin-top: 22px; margin-left: 23px">
                            <div style="display: flex; margin-right: 55px">
                              <p style="font-size: 20px; font-weight: bold">Check In Date: &nbsp;</p>
                              <p style="font-size: 20px; font-weight: 500">{{ checkInDate }}</p>
                            </div>
                            <div style="display: flex">
                              <p style="font-size: 20px; font-weight: bold">Check Out Date: &nbsp;</p>
                              <p style="font-size: 20px; font-weight: 500">{{ checkOutDate }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr style="margin-top: 38px" />
                      <div style="display: flex; margin-bottom: 5px">
                        <p style="font-size: 20px; font-weight: bold">No of guest: &nbsp;</p>
                        <p style="font-size: 20px; font-weight: 500">{{ resInfo[0].no_guest }} Guests</p>
                      </div>
                      <div class="columns">
                        <div class="columns is-12">
                          <div style="display: flex; margin-left: 10px; margin-top: 22px; margin-left: 23px">
                            <div style="display: flex; margin-right: 220px">
                              <p style="font-size: 20px; font-weight: bold">Adults: &nbsp;</p>
                              <p style="font-size: 20px; font-weight: 500">{{ AdultsInfo }}</p>
                            </div>
                            <div style="display: flex">
                              <p style="font-size: 20px; font-weight: bold">Childs: &nbsp;</p>
                              <p style="font-size: 20px; font-weight: 500">{{ childInfo }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div style="display: flex; margin-bottom: 5px">
                        <p style="font-size: 20px; font-weight: bold">Extra Breakfast: &nbsp;</p>
                        <p style="font-size: 20px; font-weight: 500">{{ extraBreakfast }}</p>
                      </div>
                      <div class="columns">
                        <div class="columns is-12">
                          <div style="display: flex; margin-left: 10px; margin-top: 22px; margin-left: 23px">
                            <div style="display: flex; margin-right: 55px">
                              <p style="font-size: 20px; font-weight: bold">Early CI Time: &nbsp;</p>
                              <p style="font-size: 20px; font-weight: 500">{{ earlyCheckIn }} N.A</p>
                            </div>
                            <div style="display: flex">
                              <p style="font-size: 20px; font-weight: bold">Late C0 Time: &nbsp;</p>
                              <p style="font-size: 20px; font-weight: 500">{{ earlyCheckOut }} N.A</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />

                      <div style="display: flex; margin-bottom: 5px; width: 200px; height: 200px">
                        <p style="font-size: 20px; font-weight: bold">Special Instruction: &nbsp;</p>
                        <p style="font-size: 20px; font-weight: 500">{{ specialIns }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="iconModal == 'dues'" style="height: 100vh">
              <!-- dues -->

              <div v-if="showPayment == false">
                <div style="background-color: #51627e; color: white; font-size: 20px; font-weight: bold">Bills Table</div>
                <div style="height: 70vh">
                  <table class="table is-fullwidth">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Date</th>
                        <th>Qnty</th>
                        <th>Cost</th>
                        <th>Discount/ S. Discount</th>
                        <th>Final Trans</th>
                      </tr>
                    </thead>
                    <tbody style="font-size: 15px">
                      <tr v-for="bill in billData" :key="bill.id">
                        <template v-if="bill.bill_item == 'Room Clearance'">
                          <td>Payment ({{ bill.trx_type }})</td>
                        </template>
                        <template v-else>
                          <td>{{ bill.bill_item }}</td>
                        </template>
                        <td>{{ bill.date }}</td>
                        <template v-if="bill.bill_category == 'debit'">
                          <td>{{ bill.item_quantity }}</td>
                        </template>
                        <template v-else>
                          <td>N.A</td>
                        </template>
                        <template v-if="bill.bill_category == 'debit' && bill.type_bill == 'room'">
                          <td>{{ (bill.cost / bill.item_quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
                        </template>
                        <template v-else-if="bill.bill_category == 'debit' && bill.type_bill == 'laundry'">
                          <td>{{ bill.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
                        </template>
                        <template v-else-if="bill.bill_category == 'debit' && bill.type_bill == 'room_service'">
                          <td>{{ bill.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
                        </template>
                        <template v-else>
                          <td>{{ bill.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
                        </template>
                        <template v-if="bill.bill_category == 'debit'">
                          <td>{{ bill.discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} (0%)</td>
                        </template>
                        <template v-else>
                          <td>{{ bill.special_discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
                        </template>
                        <template v-if="bill.bill_category == 'debit'">
                          <td>{{ ((bill.cost / bill.item_quantity) * bill.item_quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
                        </template>
                        <template v-else>
                          <td>(-){{ bill.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
                        </template>
                      </tr>
                    </tbody>
                  </table>
                  <hr style="margin-top: -20px" />

                  <!-- <div style="float:right; font-size:18px; margin-right:10px; font-weight:bold;">Total Bill: {{resInfo.total_amount}}</div> -->

                  <div style="text-align: right; font-size: 18px; margin-right: 10px">Sub Total: {{ (parseInt(roomCost) + laundryCalculated + roomServiceCalculated).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>

                  <div style="text-align: right; font-size: 18px; margin-right: 10px">VAT({{ companyInfo.tax }}%): {{ (roomCost * (companyInfo.tax / 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>

                  <div style="text-align: right; font-size: 18px; margin-right: 10px">Service Charge({{ companyInfo.service_charge }}%): {{ (roomCost * (companyInfo.service_charge / 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>

                  <template v-if="laundryVatAmount != 0 || laundryServiceCharge != 0">
                    <div style="text-align: right; font-size: 18px; margin-right: 10px">Laundry VAT & SD({{ laundryVatAmount }}% | {{ laundryServiceCharge }}%): {{ laundryCalculatedDuty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>
                  </template>
                  <template v-if="roomServiceVat != 0 || roomServiceServiceCharge != 0">
                    <div style="text-align: right; font-size: 18px; margin-right: 10px">RoomService VAT & SD({{ roomServiceVat }}%|{{ roomServiceServiceCharge }}%): {{ roomServiceCalculatedDuty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>
                  </template>

                  <div style="text-align: right; font-size: 18px; margin-right: 10px">Discount(s):(-) {{ resInfo[0].previous_discounts.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>

                  <hr />
                  <p style="text-align: right; margin-right: 10px; margin-top: -20px; font-size: 18px">Grand Total: {{ (laundryCalculated + roomServiceCalculated + laundryCalculatedDuty + roomServiceCalculatedDuty + roomCost + roomCost * (companyInfo.tax / 100) + roomCost * (companyInfo.service_charge / 100) - resInfo[0].previous_discounts).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>
                  <br />
                  <p style="text-align: right; margin-right: 10px; margin-top: -20px; font-size: 18px">Advance & Payment(s): (-) {{ (advanceCalculated + roomClearanceCalculated).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>

                  <template v-if="resInfo[0].total_due != 0">
                    <hr />
                    <p style="text-align: right; margin-right: 10px; margin-top: -20px; font-size: 18px">Total Due(s): {{ (laundryCalculated + roomServiceCalculated + laundryCalculatedDuty + roomServiceCalculatedDuty + roomCost + roomCost * (companyInfo.tax / 100) + roomCost * (companyInfo.service_charge / 100) - resInfo[0].previous_discounts - advanceCalculated - roomClearanceCalculated).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>
                  </template>
                  <template v-else>
                    <hr />
                    <p style="text-align: right; margin-right: 10px; margin-top: -20px; font-weight: bold; font-size: 18px; color: red">PAID</p>
                  </template>
                  <br />
                  <hr />

                  <div style="float: left; font-size: 18px; margin-left: 10px; font-weight: bold">Credited Amount: {{ resInfo[0].total_credit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</div>
                  <div style="float: right; font-size: 18px; margin-right: 10px; font-weight: bold">Total Due: {{ resInfo[0].total_due.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</div>
                  <br />
                  <br />
                  <template v-if="resInfo[0].total_due != 0">
                    <div style="margin-right: 20px; margin-top: 80px; float: right"><button class="button" style="background-color: #26ade4; color: white" @click.prevent="showPayment = !showPayment">Payment</button></div>
                  </template>
                  <button
                    class="button"
                    style="float: right; margin-top: 80px; background-color: #099dd9; color: white; margin-right: 10px"
                    @click.prevent="
                      dateCal();
                      printPDF();
                    "
                  >
                    Print
                  </button>
                </div>

                <br />
                <br />
                <button
                  class="button"
                  style="float: left; margin-top: 80px; margin-left: 10px; background-color: "
                  @click.prevent="
                    showPayment = false;
                    advanceScreen = false;
                    (rejectModal = false), (modalClose = false);
                  "
                >
                  Back
                </button>
              </div>
              <div v-else>
                <div class="tabs is-centered is-medium">
                  <ul>
                    <li v-bind:class="{ 'is-active': isA == 'cash' }"><a v-on:click="(isA = 'cash'), (trxType = 'cash')">Cash</a></li>
                    <li v-bind:class="{ 'is-active': isA == 'card' }"><a v-on:click="(isA = 'card'), (trxType = 'card')">Card</a></li>
                    <li v-bind:class="{ 'is-active': isA == 'bkash' }"><a v-on:click="(isA = 'bkash'), (trxType = 'bkash')">bkash</a></li>
                  </ul>
                </div>
                <div style="float: left; font-size: 18px; margin-left: 10px; font-weight: bold">Credited Amount: {{ resInfo[0].total_credit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</div>
                <div style="float: right; font-size: 18px; margin-right: 10px; font-weight: bold">Total Due: {{ resInfo[0].total_due.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</div>
                <br />
                <hr />
                <div class="tab-contents">
                  <div class="content" v-bind:class="{ 'is-active': isA == 'cash' }" style="height: 70vh">
                    <div style="margin-left: 8vw">
                      <div class="field is-horizontal">
                        <div class="field-label is-normal">
                          <label class="label" style="font-size: 18px; width: 180px">Special Discount:</label>
                        </div>
                        <div class="field-body">
                          <div class="field">
                            <p class="control" style="">
                              <input class="input" type="text" placeholder="Discount Amount" v-model="amountDiscount" style="width: 300px; float: left" />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="field is-horizontal">
                        <div class="field-label is-normal">
                          <label class="label" style="font-size: 18px; width: 180px">Remarks S. Discount:</label>
                        </div>
                        <div class="field-body">
                          <div class="field">
                            <p class="control" style="">
                              <input class="input" type="text" placeholder="Reason" v-model="specialDiscountReason" style="width: 300px; float: left" />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="field is-horizontal">
                        <div class="field-label is-normal">
                          <label class="label" style="font-size: 18px; width: 180px">Amount:</label>
                        </div>
                        <div class="field-body">
                          <div class="field">
                            <p class="control">
                              <input class="input" type="text" placeholder="Credit amount" v-model="amount_credited" style="width: 300px; float: left" />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-contents">
                  <div class="content" v-bind:class="{ 'is-active': isA == 'card' }" style="height: 70vh">
                    <div style="margin-left: 8vw">
                      <div class="field is-horizontal">
                        <div class="field-label is-normal">
                          <label class="label" style="font-size: 18px; width: 180px">Special Discount:</label>
                        </div>
                        <div class="field-body">
                          <div class="field">
                            <p class="control" style="">
                              <input class="input" type="text" placeholder="Discount Amount" v-model="amountDiscount" style="width: 300px; float: left" />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="field is-horizontal">
                        <div class="field-label is-normal">
                          <label class="label" style="font-size: 18px; width: 180px">Remarks S. Discount:</label>
                        </div>
                        <div class="field-body">
                          <div class="field">
                            <p class="control" style="">
                              <input class="input" type="text" placeholder="Reason" v-model="specialDiscountReason" style="width: 300px; float: left" />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="field is-horizontal">
                        <div class="field-label is-normal">
                          <label class="label" style="font-size: 18px; width: 180px">Amount:</label>
                        </div>
                        <div class="field-body">
                          <div class="field">
                            <p class="control">
                              <input class="input" type="text" placeholder="Credit amount" v-model="amount_credited" style="width: 300px; float: left" />
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="field is-horizontal">
                        <div class="field-label is-normal">
                          <label class="label" style="font-size: 18px; width: 180px">Card no:</label>
                        </div>
                        <div class="field-body">
                          <div class="field">
                            <p class="control">
                              <input class="input" type="text" placeholder="Card no" maxlength="16" v-model="trans_id" style="width: 300px; float: left" />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="field is-horizontal">
                        <div class="field-label is-normal">
                          <label class="label" style="font-size: 18px; width: 180px">Date of expiry:</label>
                        </div>
                        <div class="field-body">
                          <div class="field">
                            <p class="control">
                              <input class="input" type="date" placeholder="Discount amount" v-model="doe_card" style="width: 300px; float: left" />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-contents">
                  <div class="content" v-bind:class="{ 'is-active': isA == 'bkash' }" style="height: 70vh">
                    <div style="margin-left: 8vw">
                      <div class="field is-horizontal">
                        <div class="field-label is-normal">
                          <label class="label" style="font-size: 18px; width: 180px">Special Discount:</label>
                        </div>
                        <div class="field-body">
                          <div class="field">
                            <p class="control" style="">
                              <input class="input" type="text" placeholder="Discount Amount" v-model="amountDiscount" style="width: 300px; float: left" />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="field is-horizontal">
                        <div class="field-label is-normal">
                          <label class="label" style="font-size: 18px; width: 180px">Remarks S. Discount:</label>
                        </div>
                        <div class="field-body">
                          <div class="field">
                            <p class="control" style="">
                              <input class="input" type="text" placeholder="Reason" v-model="specialDiscountReason" style="width: 300px; float: left" />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="field is-horizontal">
                        <div class="field-label is-normal">
                          <label class="label" style="font-size: 18px; width: 180px">Amount:</label>
                        </div>
                        <div class="field-body">
                          <div class="field">
                            <p class="control">
                              <input class="input" type="text" placeholder="Credit amount" v-model="amount_credited" style="width: 300px; float: left" />
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="field is-horizontal">
                        <div class="field-label is-normal">
                          <label class="label" style="font-size: 18px; width: 180px">Transaction id:</label>
                        </div>
                        <div class="field-body">
                          <div class="field">
                            <p class="control">
                              <input class="input" type="text" placeholder="Discount amount" maxlength="18" v-model="trans_id" style="width: 300px; float: left" />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="display: flex; margin-left: 20vw">
                  <div><button class="button" style="margin-right: 5px" @click.prevent="showPayment = false">Back</button></div>

                  <div><button class="button" style="background-color: #26ade4; color: white" @click.prevent="submitPayment()">submit</button></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="column is-12" style="height: 42vh; margin-top: -20px">
      <div class="columns">
        <div class="column is-6">
          <div class="columns">
            <div class="column is-6">
              <div class="select is-fullwidth" style="">
                <select v-model="filterSelection" @change="filterRooms()">
                  <option value="" hidden><b>Filter Selection:</b></option>
                  <option v-for="filter in filterTypes" :key="filter.id">{{ filter }}</option>
                </select>
              </div>
            </div>
            <div class="column is-6" style="margin-left: -10px">
              <div class="select is-fullwidth" style="">
                <select v-model="filterRoomSelection" @change="filterRooms()">
                  <option value="" hidden><b>Room Selection:</b></option>
                  <option v-for="filter in filterRoomType" :key="filter.id">{{ filter }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div style="width: 100p%; height: 100%">
            <div style="display: flex; flex-direction: row; justify-content: left">
              <div style="width: 230px; display: flex; flex-direction: row; justify-content: left">
                <div class="tooltip" style="width: 25px; height: 25px; background-color: #eb9683; padding-top: 0px">
                  <span class="tooltiptext">Occupied</span>
                  <span></span>
                </div>
                <p style="font-size: 20px; margin-left: 10px">OC</p>
                <div class="tooltip" style="width: 25px; height: 25px; background-color: #b4dbc8; padding-top: 0px; margin-left: 10px">
                  <span class="tooltiptext">Occupied</span>
                  <span></span>
                </div>
                <p style="font-size: 20px; margin-left: 10px">AV</p>
                <div class="tooltip" style="width: 25px; height: 25px; background-color: #add8e6; padding-top: 0px; margin-left: 10px">
                  <span class="tooltiptext">Under Maintenance</span>
                  <span></span>
                </div>
                <p style="font-size: 20px; margin-left: 10px">UM</p>
                <div style="border-right: 2px solid black; margin-left: 10px"></div>
              </div>

              <!-- <div data-tooltip="Single Room"  style="width:25px; height: 25px; background-color:#b3f283; padding-top:0px; margin-left:10px;">
                <span></span>
              </div>
              <p style="font-size:20px; margin-left:10px;">SG</p>
              <div data-tooltip="Double Room"  style="width:25px; height: 25px; background-color:#652DC1; padding-top:0px; margin-left:10px;">
                <span></span>
              </div>
              <p style="font-size:20px; margin-left:10px;">DB</p>
              <div data-tooltip="Delux Room"  style="width:25px; height: 25px; background-color:#FFEB00; padding-top:0px; margin-left:10px;">
                <span></span>
              </div >
              <p style="font-size:20px; margin-left:10px;">DL</p> -->
              <!-- roomShow -->
              <div style="overflow-x: auto; width: 35vw; display: flex; flex-direction: row; justify-content: left">
                <div style="" v-for="color in dataRT" :key="color.id">
                  <div style="display: flex">
                    <div class="tooltip" :style="{ 'background-color': `${color.color}` }" style="width: 25px; height: 25px; padding-top: 0px; margin-left: 10px; cursor: pointer">
                      <span class="tooltiptext">Under Maintenance</span>
                    </div>
                    <p style="font-size: 20px; margin-left: 5px">{{ color.short }}</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- fixit -->
          </div>
        </div>
      </div>
      <div class="box" style="background-repeat: no-repeat; background-size: cover; height: 40vh; width: 100%; overflow-y: auto; margin-top: -10px; border: 1px solid #cfd8e8">
        <!-- <div class="box" style="height:40vh; width:100%; overflow-y: scroll; margin-top:-10px; border: 1px solid #cfd8e8;"> -->

        <div class="container" style="height: 100%; padding-top: 0px; margin-left: -10px">
          <ul style="margin-left: 8px" class="rooms" v-for="(room, index) in roomView" :key="room.id">
            <li>
              <div :class="roomColor(room.status_fd)" style="height: 45px; width: 85px; margin: 5px 0px 2px 10px; border: 1px solid; border-radius: 4px; text-align: left; box-shadow: 2px 2px 2px black">
                <div style="display: flex; margin-top: 5px">
                  <div :style="{ 'background-color': `${dataColor[index]}` }" style="width: 8px; height: 35px"></div>
                  <div style="margin-left: 10px">
                    <p style="font-size: 22px">{{ room.room_no }}</p>
                  </div>
                  <div style="margin-left: 5px">
                    <span v-if="room.status_fd == 'occupied'" @click="guestStatus(room)"><i class="fas fa-solid fa-user-tie" style="font-size: 18px; cursor: pointer; color: black"></i></span> <img v-if="room.status_fd == 'available'" src="../assets/plus.png" alt="plus" @click.prevent="addFunc(room, room.room_type), (iconModal = 'addResInput')" style="width: 35px; text-align: center; cursor: pointer" />
                  </div>
                  <!-- <i class="fas fa-solid fa-user-tie" v-if="room.status_fd == 'occupied'" alt="add" style="font-size:18px; cursor: pointer; color:black;"  @click="guestStatus(room)"></i> -->
                </div>
              </div>
            </li>
          </ul>

          <!-- :class="roomTypeColor(room.room_type)" -->
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { toast } from 'bulma-toast';
import api from '../boot/axios';
import axios from '../boot/axios';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import html2pdf from 'html2pdf-jspdf2';
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    VueTelInput,
  },
  data() {
    return {
      TransferBool: false,
      TransferRoomSelect: '',
      trasnferModal: false,
      roomTransferModal: false,
      logo: null,
      showPdf: false,
      showPayment: false,
      genderSelection: '',
      genderTypes: ['Mr.', 'Ms.', 'Mrs.'],
      modalClose: false,
      roomData: [],
      filterSelection: '',
      filterTypes: ['all', 'occupied', 'available', 'maintenance'],
      filterRoomSelection: '',
      filterRoomType: ['all', 'Single', 'Double', 'Delux'],
      roomView: null,
      rejectModal: false,
      tempAdd: null,
      email: '',
      firstName: '',
      fName: '',
      LName: '',
      eMail: '',
      aDress: '',
      pHone: 0,
      gSelect: '',
      lastName: '',
      passport: '',
      address: '',
      phone: null,
      custId: null,
      checkInDate: null,
      checkInTime: null,
      checkOutDate: null,
      checkOutTime: null,
      resInfo: [''],
      custInfo: '',
      roomAddType: '',
      guestNum: '',
      iconModal: '',
      showInfo: false,
      companyInfo: '',
      isA: 'cash',
      trans_id: '',
      doe_card: '',
      amount_credited: '',
      statusR: false,
      specialIns: '',
      roomRepData: [],
      selectedRoomTypeData: '',
      advanceScreen: false,
      addRoomCount: [],
      cardString: '',
      guestNo: '',

      AdultsInfo: '',
      childInfo: '',
      earlyCheckIn: null,
      earlyCheckOut: null,
      extraBreakfast: null,
      roomType: '',
      roomDataB: '',
      nidDl: '',
      country: '',
      nationality: '',
      occupation: '',
      doi: '',
      dof: '',
      visa: '',
      vdoi: '',
      vdof: '',
      roomNos: [],
      tempSplit: [],
      roomNosFlat: [],
      cardData: '',
      billData: [],
      userInfo: '',
      roomCost: 0,
      amountDiscount: 0,
      totalAmount: 0,
      specialDiscountReason: '',
      due_amount: '',
      creditCalculated: '',
      roomStatus: [],
      cusPic: [],
      advanceCalculated: 0,
      roomClearanceCalculated: 0,
      totalCalculated: 0,
      dayCount: 0,
      trxType: 'cash',
      customerData: [],
      customerFillData: '',
      showBox: false,
      cusPicData: '',
      dataRT: [],
      roomTypeArr: [],
      dataColor: [],
      compareCustCount: 0,
      compareCustData: '',
      allCustData: [],
      tempRoom: null,
      addBillCalculated: 0,
      laundryCalculated: 0,
      laundryCalculatedDuty: 0,
      roomServiceCalculatedDuty: 0,
      roomServiceCalculated: 0,
      laundryVatAmount: 0,
      laundryServiceCharge: 0,
      roomServiceVat: 0,
      roomServiceServiceCharge: 0,
      minDate: '',
      minFromDate: '',
      calcDate: '',
      calcTime: '',
      company: '',
      secondModal: false,
      pic: null,
      tempSplitVal: [],
      secondaryRoomInfo: [],
      primaryRoomInfo: '',
      roomBool: '',
      roomText: '',
      roomVacantDataTemp: [],
      roomVacantData: [],
      tansferPrimary: '',
      secondaryRoomPoint: '',
    };
  },
  computed: {
    resultQuery() {
      if (this.pHone) {
        console.log('nitese', this.pHone);
        return this.customerData.filter((item) => {
          console.log(item.phone);
          return this.pHone
            .toString()
            .split(' ')
            .every((v) => item.phone.toString().includes(v));
        });
      } else {
        return this.customerData.last_name + this.customerData.first_name;
      }
    },
  },
  created() {
    this.getLogo();
    this.fillResInfo();
    this.getRoomInfo();
    this.roomStatus = [0, 0, 0, 0];
    this.getRoomReport();
    this.companyInfoData();
    this.getCustomerData();
    this.getDataColor();
    this.getCompany();
  },
  methods: {
    async callME() {
      alert('hello');
    },
    async submitTransferRoom() {
      let tempCard = '';
      console.log(this.resInfo[0]);
      console.log(this.tansferPrimary);

      if (this.tansferPrimary == 'primary') {
        await api
          .put(`api/room/${this.resInfo[0].room}/`, {
            room_no: this.resInfo[0].room,
            status_fd: 'available',
          })
          .catch((err) => console.log(err));
        await api
          .put(`api/room/${this.TransferRoomSelect.room_no}/`, {
            room_no: this.TransferRoomSelect.room_no,
            status_fd: 'occupied',
          })
          .catch((err) => console.log(err));
        await api
          .get(`api/card/?rev_id=${this.resInfo[0].id}`)
          .then((res) => {
            tempCard = res.data[0];
          })
          .catch((err) => console.log(err));
        console.log(tempCard);
        await api
          .put(`api/card/${tempCard.card_no}/`, {
            card_no: tempCard.card_no,
            room_no: this.TransferRoomSelect.room_no,
          })
          .catch((err) => console.log(err));

        await api
          .post(`api/room_t/`, {
            card_no: tempCard.card_no,
            room_no_to: this.TransferRoomSelect.room_no,
            room_no_from: this.secondaryTransfer,
          })
          .catch((err) => console.log(err));
        console.log(tempCard);
        await api
          .put(`api/reservation/${this.resInfo[0].id}/`, {
            status: this.resInfo[0].status,
            cust_id: this.resInfo[0].cust_id,
            room: this.TransferRoomSelect.room_no,
          })
          .then(
            toast({
              message: 'Primary Room Number has been changed',
              type: 'is-info',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            })
          )
          .catch((err) => console.log(err));

        console.log(this.TransferRoomSelect.room_no);
        this.TransferBool = false;
        this.trasnferModal = false;
        this.$router.go();
      }
      if (this.tansferPrimary == 'secondary') {
        console.log('second');
        //edithere
        let roomArray = [];
        let splitArray = [];
        let tempCard = '';

        splitArray = this.resInfo[0].secondary_room.split(',');
        for (let i = 0; i < splitArray.length; i++) {
          roomArray.push(splitArray[i]);
        }
        console.log(splitArray);
        console.log(roomArray);
        console.log('yo', this.secondaryTransfer);
        let tempRoomFiltered = splitArray.filter((a) => a != this.secondaryTransfer);
        console.log('yoyi', tempRoomFiltered);
        tempRoomFiltered.push(this.TransferRoomSelect.room_no);
        let roomsConcat = '';
        roomsConcat += tempRoomFiltered + ',';

        console.log(roomsConcat.slice(0, -1));

        await api
          .put(`api/room/${this.secondaryTransfer}/`, {
            room_no: this.secondaryTransfer,
            status_fd: 'available',
          })
          .catch((err) => console.log(err));

        await api
          .put(`api/room/${this.TransferRoomSelect.room_no}/`, {
            room_no: this.TransferRoomSelect.room_no,
            status_fd: 'occupied',
          })
          .catch((err) => console.log(err));

        await api
          .get(`api/card/?rev_id=${this.resInfo[0].id}`)
          .then((res) => {
            tempCard = res.data[0];
          })
          .catch((err) => console.log(err));
        console.log(tempCard);
        await api
          .put(`api/card/${tempCard.card_no}/`, {
            card_no: tempCard.card_no,
            secondary_room: roomsConcat.slice(0, -1),
          })
          .catch((err) => console.log(err));

        await api
          .post(`api/room_t/`, {
            card_no: tempCard.card_no,
            room_no_to: this.TransferRoomSelect.room_no,
            room_no_from: this.secondaryTransfer,
          })
          .catch((err) => console.log(err));

        await api
          .put(`api/reservation/${this.resInfo[0].id}/`, {
            status: this.resInfo[0].status,
            cust_id: this.resInfo[0].cust_id,
            secondary_room: roomsConcat.slice(0, -1),
          })
          .then(
            toast({
              message: 'SecondarynRoom Number has been changed',
              type: 'is-info',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            })
          )
          .catch((err) => console.log(err));
      }
      this.$router.go();
      this.transferPrimary = '';
    },
    async SearchTransferDate() {
      console.log('i am called');

      this.roomBool = '';
      this.roomText = '';
      this.resData = [];
      this.roomVacantData = [];
      this.roomVacantDataTemp = [];
      this.roomData = [];
      let co = this.checkInDate;
      let cd = this.checkOutDate;

      await api
        .get('api/rmrt/')
        .then((res) => {
          this.roomData = res.data;
          //console.log("room",this.roomData)
        })
        .catch((err) => console.log(err));
      await api
        .get('api/reservation/')
        .then((res) => {
          this.resData = res.data;
          //console.log("res",this.resData)
        })
        .catch((err) => console.log(err));

      let varr = [];
      for (let i = 0; i < this.resData.length; i++) {
        const a = {
          room: parseInt(this.resData[i].room),
          check_in_date: this.resData[i].check_in_date,
          check_out_date: this.resData[i].check_out_date,
          status: this.resData[i].status,
        };
        varr.push(a);
        if (this.resData[i].secondary_room != '' && this.resData[i].secondary_room != null) {
          let b = this.resData[i].secondary_room.split(',');
          for (let j = 0; j < b.length; j++) {
            const c = {
              room: parseInt(b[j]),
              check_in_date: this.resData[i].check_in_date,
              check_out_date: this.resData[i].check_out_date,
              status: this.resData[i].status,
            };
            varr.push(c);
          }
        }
      }
      this.resData = varr;

      if (cd != '' && co != '') {
        for (let i = 0; i < this.roomData.length; i++) {
          this.b1 = false;
          this.b2 = false;
          this.b3 = false;
          this.b4 = false;
          this.b5 = false;

          let b11 = false;
          let b22 = false;
          let b33 = false;

          for (let a = 0; a < this.resData.length; a++) {
            if (this.resData[a].status == 'booked') {
              if (co >= this.resData[a].check_in_date && co < this.resData[a].check_out_date) {
                b22 = true;
              }
              if (cd > this.resData[a].check_in_date && cd <= this.resData[a].check_out_date) {
                b11 = true;
              }
              if (co < this.resData[a].check_in_date && cd > this.resData[a].check_out_date) {
                b33 = true;
              }
            }
            if (b11 == true || b22 == true || b33 == true) {
              if (!this.roomBooked.includes(this.resData[a])) {
                this.roomBooked.push(this.resData[a]);
              }
            }
            b11 = false;
            b22 = false;
            b33 = false;

            if (this.roomData[i].room_no == this.resData[a].room && this.resData[a].status != 'booked') {
              if (this.resData[a].status == 'OnHold') {
                this.b5 = true;
                break;
              }
              if (this.resData[a].status == 'Checked In') {
                this.b5 = true;
                break;
              }
              if (cd > this.resData[a].check_in_date && cd < this.resData[a].check_out_date) {
                // console.log(true)
                this.b1 = true;
                break;
              }
              if (co > this.resData[a].check_in_date && co < this.resData[a].check_out_date) {
                //console.log(true)
                this.b2 = true;
                break;
              }
              if (this.resData[a].check_in_date > co && this.resData[a].check_in_date < cd) {
                // console.log(true)
                this.b3 = true;
                break;
              }
              if (this.resData[a].check_out_date > co && this.resData[a].check_out_date < cd) {
                //console.log(true)
                this.b4 = true;
                break;
              }
              if (this.resData[a].check_in_date == co && this.resData[a].check_out_date == cd) {
                this.b1 = true;
                break;
              }
            }
          }
          if (this.b1 == false && this.b2 == false && this.b3 == false && this.b4 == false && this.b5 == false) {
            this.roomVacantData.push(this.roomData[i]);
          }
        }
        this.roomVacantDataTemp = this.roomVacantData;
        console.log('temp', this.roomVacantDataTemp);

        console.log('value should be here');

        console.log(this.roomVacantData);
      }
    },
    async getPrimarySecondaryRooms() {
      console.log(this.resInfo[0]);
      this.roomNosFlat.length = 0;
      this.roomNos.length = 0;

      this.secondaryRoomInfo.length = 0;
      await api
        .get(`api/rmrt/${this.resInfo[0].room}/`)
        .then((res) => {
          this.primaryRoomInfo = res.data;
        })
        .catch((err) => console.log(err));
      console.log('primary', this.primaryRoomInfo);

      if (this.resInfo[0].secondary_room != null && this.resInfo[0].secondary_room != '') {
        this.tempSplit = this.resInfo[0].secondary_room.split(',');

        console.log('yo man', this.tempSplit);
      }
      this.roomNos.push(this.tempSplit);
      this.roomNosFlat = this.roomNos.flat();

      console.log(this.tempSplit);
      console.log(this.roomNosFlat);

      for (let i = 0; i < this.roomNosFlat.length; i++) {
        await api
          .get(`api/rmrt/${this.roomNosFlat[i]}/`)
          .then((res) => {
            this.secondaryRoomInfo.push(res.data);
          })
          .catch((err) => console.log(err));
      }
      console.log(this.secondaryRoomInfo);
    },
    async getLogo() {
      await axios
        .get('auth/hotel_logo/1/')
        .then((res) => {
          this.logo = res.data.pic;
          this.pic = res.data.back01;
        })
        .catch((err) => console.log(err));
    },
    checkForNew() {
      if (this.resultQuery.length == 0) {
        this.showBox = false;
      }
    },
    fillResInfo() {
      this.resInfo[0] = { previous_discounts: 0 };
    },
    async getCompany() {
      await axios
        .get('/auth/company/1/')
        .then((res) => {
          this.company = res.data;
          console.log(this.company);
        })
        .catch((err) => console.log(err));
    },
    dateCal() {
      this.calcDate = new Date().toLocaleString('en-US', { timeZone: 'Asia/Dhaka' }).substr(0, 9);
      this.calcTime = new Date().toLocaleString('en-US', { timeZone: 'Asia/Dhaka' }).substr(10, 11);
    },
    printPDF() {
      this.showPdf = true;
      var element = document.getElementById('pdfportion');
      console.log('print');

      var opt = {
        margin: 0.2,
        filename: `${this.cardData.card_no}.pdf`,
        image: { type: 'png' },
        html2canvas: { scale: 2 },
        pagebreak: { mode: 'avoid-all' },
        jsPDF: {
          orientation: 'p',
          unit: 'in',
          format: 'a4',
          putOnlyUsedFonts: true,
          floatPrecision: 16,
        },
      };

      html2pdf().set(opt).from(element).save();
      this.showPdf = false;
    },
    checkMinDate() {
      this.minDate = new Date().toISOString().split('T')[0];
      return this.minDate;
    },
    checkFromDate() {
      this.minFromDate = this.checkInDate;
      return this.minFromDate;
    },
    async getDataColor() {
      await api
        .get('api/room_type/')
        .then((response) => {
          this.dataRT = response.data;
          console.table(this.dataRT);
        })
        .catch((error) => {
          console.log(error);
        });

      await api
        .get('api/room/')
        .then((res) => {
          this.roomTypeArr = res.data;
        })
        .catch((err) => console.log(err));
      console.log('datacolor', this.dataRT);

      for (let i = 0; i < this.roomTypeArr.length; i++) {
        for (let j = 0; j < this.dataRT.length; j++) {
          if (this.roomTypeArr[i].room_type == this.dataRT[j].type_name) {
            this.dataColor.push(this.dataRT[j].color);
            // console.log("colorrr",this.dataRT[j].room_type)
            break;
          }
        }
      }
      console.log('color', this.dataColor);
    },
    async fillInputs(id) {
      console.log('hello');
      this.showBox = false;
      await api
        .get(`api/customer/${id}/`)
        .then((res) => {
          this.customerFillData = res.data;
        })
        .catch((err) => console.log(err));
      console.log('customer data', this.customerFillData);

      this.fName = this.customerFillData.first_name;
      this.LName = this.customerFillData.last_name;
      this.eMail = this.customerFillData.email;
      this.aDress = this.customerFillData.address;
      this.pHone = this.customerFillData.phone;
      this.gSelect = this.customerFillData.gender;
    },
    async companyInfoData() {
      await api
        .get(`auth/company/1/`)
        .then((res) => {
          this.companyInfo = res.data;
        })
        .catch((err) => console.log(err));
      console.log('yo company', this.companyInfo);
    },
    async urgentClean() {
      console.log(this.resInfo[0].room);
      await api
        .put(`api/room/${this.resInfo[0].room}/`, {
          room_no: this.resInfo[0].room,
          status_hk: 'urgent',
        })
        .then(
          toast({
            message: 'Request to clean room has been sent',
            type: 'is-info',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          })
        )
        .catch((err) => console.log(err));
    },
    OnFileUpload(event) {
      this.pot = event.target.files[0];
      console.log(event);
    },

    async uploadImageHandler() {
      const fd = new FormData();

      //call cuspic here with cus id
      console.log(this.resInfo.cust_id);

      fd.append('pic', this.pot);
      fd.append('cust_id', this.custInfo.id);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      await api
        .get(`api/cuspic/?cust_id=${this.custInfo.id}`)
        .then((res) => {
          this.cusPicData = res.data;
        })
        .catch((err) => console.log(err));
      console.log(this.cusPicData);

      if (this.cusPicData.length == 0) {
        console.log('hello');
        await api.post(`api/cuspic/`, fd, config).then((res) => {
          console.log('pic upload: ', res.data);
        });
      } else {
        await api.put(`api/cuspic/${this.cusPicData.cust_id}`, fd, config).then((res) => {
          console.log('pic upload: ', res.data);
        });
      }
      //change
    },

    async submitResInfo() {
      console.log(this.resInfo);

      await api
        .put(`api/reservation/${this.resInfo.id}/`, {
          check_in_date: this.resInfo.checkInDate,
          check_in_time: this.checkInTime,
          cust_id: this.resInfo.cust_id,
          room: this.resInfo.room,
          source: 'FrontDesk',
          status: this.resInfo.status,
          check_out_date: this.resInfo.checkOutDate,
          check_out_time: this.checkOutTime,
          no_guest: this.guestNum,
          special_ins: this.specialIns,
          extra_breakfast: this.extraB,
          No_days: this.resInfo.No_days,
          adults: this.adultsNum,
          childs: this.childNum,
        })
        .then(
          toast({
            message: 'Reservation has been modified',
            type: 'is-info',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          })
        )
        .catch((err) => console.log(err));
    },
    async submitModInfo() {
      console.log(this.custInfo);
      await api
        .put(`api/customer/${this.custInfo.id}/`, {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          address: this.address,
          phone: this.phone,
          gender: this.genderSelection,
          nid: this.nidDl,
          country: this.country,
          nationality: this.nationality,
          occupation: this.occupation,
          passport: this.passport,
          passport_exp: this.dof,
          visa_type: this.visa,
          visa_issue: this.vdoi,
          visa_expiry: this.vdof,
        })
        .then(
          toast({
            message: 'Customer Info has been updated',
            type: 'is-info',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          })
        )
        .catch((err) => console.log(err));

      await this.uploadImageHandler();

      await this.getCustPic();
    },
    async getCustomerData() {
      await api
        .get('api/customer/')
        .then((res) => {
          this.customerData = res.data;
        })
        .catch((err) => console.log(err));
      console.log('customer data', this.customerData);
    },

    async getCustPic() {
      if (this.cusPic != null) {
        this.cusPic.length = 0;
      }

      await api
        .get(`api/cuspic/?cust_id=${this.custInfo.id}`)
        .then((res) => {
          console.log('pic data', res.data);
          this.cusPic = res.data[0];
        })
        .catch((err) => console.log(err));
      console.log('pic', this.cusPic);
      if (typeof this.cusPic == 'undefined') {
        this.cusPic = [];
      }
    },
    async getResAndCusInfo() {
      //console.log('info',this.resInfo)

      // this.roomNos.push(this.resInfo.room)

      // if(this.resInfo.secondary_room != null || this.resInfo.secondary_room != ''){
      //     this.tempSplit=this.resInfo.secondary_room.split(',')
      //     this.tempSplit.pop()
      //     console.log("yo man",this.tempSplit)
      // }
      // this.roomNos.push(this.tempSplit)
      // this.roomNosFlat=this.roomNos.flat()

      // get secondary reservation room
      // await api.get(`reservation/?primary_room_no=${this.resInfo.room}`
      // ).then(res =>{
      //     this.secondaryRooms = res.data
      //     console.log('rooms sendondary',this.secondaryRooms)
      // }).catch(err =>{
      //     console.log(err)
      // })

      //customer info
      await api
        .get(`api/customer/${this.resInfo[0].cust_id}/`)
        .then((res) => {
          this.custInfo = res.data;
          console.log(this.custInfo);
        })
        .catch((err) => {
          console.log(err);
        });
      this.firstName = this.custInfo.first_name;
      this.lastName = this.custInfo.last_name;
      this.phone = this.custInfo.phone;
      this.email = this.custInfo.email;
      this.address = this.custInfo.address;
      this.genderSelection = this.custInfo.gender;
      this.passport = this.custInfo.passport;
      this.nidDl = this.custInfo.nid;
      this.dof = this.custInfo.passport_exp;
      this.visa = this.custInfo.visa_type;
      this.vdoi = this.custInfo.visa_issue;
      this.vdof = this.custInfo.visa_expiry;
      this.nationality = this.custInfo.nationality;
      this.country = this.custInfo.country;
      this.occupation = this.custInfo.occupation;

      await this.getCustPic();
    },
    async getRoomInfo() {
      await api.get('api/room/').then((res) => {
        this.roomData = res.data;
        console.log(' yo yo', res.data);
      });
      this.roomView = this.roomData;
      //console.log(this.roomData)
    },
    async getBills() {
      await api.get(`api/card/?rev_id=${this.resInfo[0].id}`).then((res) => {
        this.cardData = res.data[0];
        console.log(this.cardData);
      });

      await api.get(`api/bills/?card_no=${this.cardData.card_no}`).then((res) => {
        this.billData = res.data;
        console.log(this.billData);
      });
      console.log('bill temp', this.billData);

      console.log('card yo', this.cardData.card_no);
      console.log('table');
      console.table(this.billData);

      // for(let i=0; i< this.billData.length; i++){
      //     console.log(this.billData[i])
      //     this.roomCost +=  parseInt((this.billData[i].cost / this.billData[i].item_quantity)*this.billData[i].item_quantity)
      // }
      // console.log("lo",this.roomCost)

      if (this.resInfo[0].previous_discounts == null) {
        this.resInfo[0].previous_discounts = 0;
      }
      this.roomCost = 0;
      this.advanceCalculated = 0;
      this.roomClearanceCalculated = 0;

      this.dayCount = 0;
      this.laundryCalculated = 0;
      this.roomServiceCalculated = 0;
      this.laundryCalculatedDuty = 0;
      this.roomServiceCalculatedDuty = 0;

      for (let i = 0; i < this.billData.length; i++) {
        if (this.billData[i].bill_category == 'debit' && this.billData[i].type_bill == 'room_rent') {
          this.roomCost += parseInt((this.billData[i].cost / this.billData[i].item_quantity) * this.billData[i].item_quantity);
        }
      }
      for (let i = 0; i < this.billData.length; i++) {
        if (this.billData[i].bill_category == 'credit' && this.billData[i].bill_item != 'Room Clearance') {
          this.advanceCalculated += parseInt(this.billData[i].cost);
        }
        if (this.billData[i].bill_category == 'credit' && this.billData[i].bill_item == 'Room Clearance') {
          this.roomClearanceCalculated += parseInt(this.billData[i].cost);
        }
      }
      for (let i = 0; i < this.billData.length; i++) {
        if (this.billData[i].bill_category == 'debit' && this.billData[i].type_bill == 'laundry') {
          console.log('yo yo', this.billData[i]);
          this.laundryCalculated += parseInt(this.billData[i].cost);
          this.laundryCalculatedDuty = (parseInt(this.billData[i].cost) * parseInt(this.billData[i].vat_amount)) / 100 + (parseInt(this.billData[i].cost) * parseInt(this.billData[i].sc_amount)) / 100;
          this.laundryVatAmount = parseInt(this.billData[i].vat_amount);
          this.laundryServiceCharge = parseInt(this.billData[i].sc_amount);
        }
        if (this.billData[i].bill_category == 'debit' && this.billData[i].type_bill == 'room_service') {
          this.roomServiceCalculated += parseInt(this.billData[i].cost);
          this.roomServiceCalculatedDuty = (parseInt(this.billData[i].cost) * parseInt(this.billData[i].vat_amount)) / 100 + (parseInt(this.billData[i].cost) * parseInt(this.billData[i].sc_amount)) / 100;
          this.roomServiceVat = parseInt(this.billData[i].vat_amount);
          this.roomServiceServiceCharge = parseInt(this.billData[i].sc_amount);
        }
      }

      console.log('lo', this.laundryCalculated);
      console.log('lo lo', this.laundryCalculatedDuty);
    },
    async submitPayment() {
      console.log(this.resInfo[0].total_due);
      console.log(this.amount_credited);
      this.due_amount = 0;
      this.due_amount = this.resInfo[0].total_due - this.amountDiscount - this.amount_credited;
      console.log(this.due_amount);
      this.creditCalculated = parseInt(this.resInfo[0].total_credit) + parseInt(this.amount_credited);
      console.log(this.creditCalculated);

      await api
        .put(`api/reservation/${this.resInfo[0].id}/`, {
          cust_id: this.resInfo[0].cust_id,
          status: this.resInfo[0].status,
          total_due: this.due_amount,
          total_credit: this.creditCalculated,
          previous_discounts: parseInt(this.resInfo[0].previous_discounts) + parseInt(this.amountDiscount),
        })
        .then(
          toast({
            message: 'Bill payment updated',
            type: 'is-info',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          })
        )
        .catch((err) => console.log(err));

      if (this.trxType == 'cash') {
        await api
          .post(`api/bills/`, {
            cost: this.amount_credited,
            trx_no: this.trans_id,
            special_discount: this.amountDiscount,
            special_discount_reason: this.specialDiscountReason,
            card_validity: this.doe_card,
            trx_type: 'cash',
            //
            type_bill: 'room',
            is_paid: false,
            bill_item: `Room Clearance`,
            card_no: this.cardData.card_no,
            bill_category: 'credit',
          })
          .catch((err) => console.log(err));
      }
      if (this.trxType == 'card') {
        await api
          .post(`api/bills/`, {
            cost: this.amount_credited,
            trx_no: this.trans_id,
            special_discount: this.amountDiscount,
            special_discount_reason: this.specialDiscountReason,
            card_validity: this.doe_card,
            trx_type: 'card',
            //
            type_bill: 'room',
            is_paid: false,
            bill_item: `Room Clearance`,
            card_no: this.cardData.card_no,
            bill_category: 'credit',
          })
          .catch((err) => console.log(err));
      }
      if (this.trxType == 'bkash') {
        await api
          .post(`api/bills/`, {
            cost: this.amount_credited,
            trx_no: this.trans_id,
            special_discount: this.amountDiscount,
            special_discount_reason: this.specialDiscountReason,
            card_validity: this.doe_card,
            trx_type: 'bkash',
            //
            type_bill: 'room',
            is_paid: false,
            bill_item: `Room Clearance`,
            card_no: this.cardData.card_no,
            bill_category: 'credit',
          })
          .catch((err) => console.log(err));
      }
      //gl
      await this.jeAdd();

      this.showPayment = false;
      await this.getBills();

      await this.guestStatus(this.tempRoom);
      this.tempRoom = null;
    },

    async jeAdd() {
      let dataTT = [];
      let dataCAO = [];
      let user = [];
      console.log(user);
      // await axios
      //     .get(`auth/currentuser/`)
      //     .then(response => {
      //         user = response.data
      //     })
      //     .catch(error => {
      //             console.log(error)
      //         })
      await api
        .get('api/Transaction_table2/')
        .then((response) => {
          dataTT = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get('api/coa/')
        .then((response) => {
          dataCAO = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      let c = 1;
      if (!dataTT.length == 0) {
        c = parseInt(dataTT[dataTT.length - 1].journal_id) + 1;
      }
      let q = 0;
      for (let i = 0; i < dataCAO.length; i++) {
        if (dataCAO[i].cost_center === 'room_clearance' && dataCAO[i].type === 'Credit') {
          console.log(dataCAO[i].id);
          q = dataCAO[i].id;
        }
      }
      const a = {
        description: 'Room Clearance', //change
        date: new Date().toISOString().substr(0, 10),
        account_id: q,
        credited_by: 'Frontdesk',
        reference: this.cardData.card_no, //change
        credit: parseInt(this.amount_credited), //change
        debit: 0, //change
        memo: 'R' + this.resInfo[0].room, //change
        journal_id: c,
        auto_journal: true,
      };
      await api.post('api/Transaction_table/', a).catch((err) => {
        console.log(err);
      });
    },
    roomColor(status) {
      //console.log('color', status)
      if (status == 'occupied') {
        //console.log('color', status)
        return { occupied: 'red' };
      }
      if (status == 'available') {
        return { available: 'green' };
      }
      if (status == 'maintenance') {
        return { maintenance: 'blue' };
      }
    },
    roomTypeColor(type) {
      // console.log("Type: ", type)
      if (type === 'Single') {
        //console.log("hello")
        return { single: 'blue' };
      }
      if (type == 'Double') {
        return { double: 'purple' };
      }
      if (type == 'Delux') {
        return { delux: 'red' };
      }
    },
    async guestStatus(room) {
      this.secondaryRoomPoint = room;
      this.secondModal = false;

      this.tempRoom = room;
      console.log(room.room_no);
      // await api.get(`reservation/?room=${room.room_no}`
      // ).then(res=>{
      //   this.resInfo = res.data
      //   console.log("res data",res.data)
      //   this.resInfo = this.resInfo.pop()
      //   console.log(this.resInfo.custId)
      // }).catch(err => console.log(err))

      // await api.get(`customer/${this.resInfo.cust_id}/`
      // ).then(res =>{
      //   this.cusInfo = res.data
      //   console.log(this.cusInfo)
      // })

      this.roomNos = [];
      this.tempSplit = [];
      this.roomNosFlat = [];
      console.log(room, 'rooooom');

      await api
        .get(`api/reservation/?room=${room.room_no}&status=${'Checked In'}`)
        .then((res) => {
          this.resInfo = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
      console.log(this.resInfo, 'yooooooo');
      let tempResData = [];
      this.secondModal = true;
      if (this.resInfo.length == 0) {
        await api
          .get(`api/reservation_second/?search=${room.room_no}`)
          .then((res) => {
            tempResData = res.data;
          })
          .catch((err) => console.log(err));
        this.secondModal = false;
      }
      for (let i = 0; i < tempResData.length; i++) {
        if (tempResData[i].status == 'Checked In') {
          this.resInfo[0] = tempResData[i];
        }
      }
      console.log('res', this.resInfo);
      console.log('temp', tempResData);
      this.checkInDate = this.resInfo[0].check_in_date;
      this.earlyCheckIn = this.resInfo[0].check_in_time;
      this.checkOutDate = this.resInfo[0].check_out_date;
      this.earlyCheckOut = this.resInfo[0].check_out_time;
      this.guestNo = this.resInfo[0].no_guest;
      this.AdultsInfo = this.resInfo[0].adults;
      this.childInfo = this.resInfo[0].childs;
      this.extraBreakfast = this.resInfo[0].extra_breakfast;
      this.specialIns = this.resInfo[0].special_ins;
      console.log('custom', this.resInfo[0].cust_id);
      console.log('idk');
      await api.get(`api/room/${this.resInfo[0].room}/`).then((res) => {
        this.roomDataB = res.data;
        console.log('room bro', this.roomDataB);
      });

      //

      this.roomNos.push(this.resInfo[0].room);

      console.log('error not here', this.resInfo[0].secondary_room);

      if (this.resInfo[0].secondary_room != null && this.resInfo[0].secondary_room != '') {
        console.log('error here', this.resInfo[0].secondary_room);

        console.log('iam not null');
        this.tempSplit = this.resInfo[0].secondary_room.split(',');

        console.log('yo man', this.tempSplit);
      }
      this.roomNos.push(this.tempSplit);
      this.roomNosFlat = this.roomNos.flat();
      console.log('room yo', this.roomNosFlat);

      await api.get(`api/card/?rev_id=${this.resInfo[0].id}`).then((res) => {
        this.cardData = res.data[0];
        console.log(this.cardData);
      });

      await this.getResAndCusInfo();
    },

    filterRooms() {
      console.log(this.filterRoomSelection);
      console.log(this.filterSelection);
      if (this.filterSelection != 'all' && this.filterRoomSelection != 'all') {
        if (this.filterRoomSelection != '' && this.filterSelection == '') {
          this.roomView = this.roomData.filter((room) => room.room_type == this.filterRoomSelection);
        } else if (this.filterSelection != '' && this.filterRoomSelection == '') {
          this.roomView = this.roomData.filter((room) => room.status_fd == this.filterSelection);
        } else {
          this.roomView = this.roomData.filter((room) => room.status_fd == this.filterSelection && room.room_type == this.filterRoomSelection);
        }
      } else {
        this.roomView = this.roomData;
        this.filterSelection = '';
        this.filterRoomSelection = '';
      }
    },
    addFunc(room, room_type) {
      this.checkInTime = '';
      this.check_out_time = '';
      this.checkOutDate = '';
      this.checkInDate = '';
      this.guestNum = '';
      this.fName = '';
      this.LName = '';
      this.pHone = '';
      this.gSelect = '';
      this.aDress = '';
      this.eMail = '';
      this.rejectModal = true;
      console.log(room);
      this.tempAdd = room;
      this.roomAddType = room_type;
      this.modalClose = true;
    },
    modalCloseModal() {
      this.modalClose = false;
    },
    async submitGuestInfo() {
      await api
        .get('api/customer/')
        .then((res) => {
          this.allCustData = res.data;
        })
        .catch((err) => console.log(err));
      this.compareCustCount = 0;
      this.compareCustData = 0;

      for (let i = 0; i < this.allCustData.length; i++) {
        if (this.allCustData[i].phone == this.pHone) {
          this.compareCustCount++;
          this.compareCustData = this.allCustData[i];
        }
      }
      console.log(this.compareCustCount);
      console.log(this.compareCustData);
      console.log('hello');

      if (this.compareCustCount > 0) {
        this.custId = this.compareCustData.id;
        console.log('hi');
      } else {
        console.log('low');
        await api
          .post('api/customer/', {
            first_name: this.fName,
            last_name: this.LName,
            email: this.eMail,
            address: this.aDress,
            phone: this.pHone,
            gender: this.gSelect,
          })
          .catch((err) => {
            console.log(err);
          });

        await api
          .get('api/lastcustomerinfo/')
          .then((res) => {
            this.custId = res.data.id;
          })
          .catch((err) => console.log(err));
      }

      this.due_amount = this.totalCalculated - this.amountDiscount - this.amount_credited;
      console.log(this.roomCost);
      console.log(this.roomCost * (this.companyInfo.tax / 100));

      await this.postRes();
      console.log(this.due_amount);
      // console.log(this.checkInTime)
    },
    async postRes() {
      console.log('hello');

      await api
        .get(`api/customer/${this.custId}/`)
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => console.log(err));

      this.dayCount = (new Date(this.checkOutDate) - new Date(this.checkInDate)) / (1000 * 3600 * 24);
      //couuu

      await api
        .post('api/reservation/', {
          check_in_date: this.checkInDate,
          check_in_time: this.checkInTime,
          cust_id: this.custId,
          room: this.tempAdd.room_no,
          source: 'FrontDesk',
          status: 'Checked In',
          check_out_date: this.checkOutDate,
          check_out_time: this.checkOutTime,
          no_guest: this.guestNum,
          total_amount: this.totalCalculated - this.amountDiscount,
          total_due: this.due_amount,
          total_credit: this.amount_credited,
          previous_discounts: this.amountDiscount,
          No_days: this.dayCount,
          is_primary_room: true,
        })
        .then(
          toast({
            message: 'Reservation has been added',
            type: 'is-info',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          })
        )
        .catch((err) => console.log(err));
      this.rejectModal = false;

      await api
        .get('api/lastres/')
        .then((res) => {
          this.lastResData = res.data;
        })
        .catch((err) => console.log(err));

      this.cardString = this.lastResData.room + this.lastResData.id.toString().padStart(5, '0');

      await api
        .post(`api/card/`, {
          card_no: this.cardString,
          room_no: this.lastResData.room,
          cust_id: this.lastResData.cust_id,
          rev_id: this.lastResData.id,
          secondary_room: '',
          checked_in: 'true',
        })
        .catch((err) => {
          console.log(err);
        });

      await api
        .post(`api/bills/`, {
          type_bill: 'room_rent',
          cost: this.roomCost,
          vat_amount: this.roomCost * (this.companyInfo.tax / 100),
          sc_amount: this.roomCost * (this.companyInfo.service_charge / 100),
          special_discount: 0,
          bill_item: `room ${this.tempAdd.room_no}`,
          card_no: this.cardString,
          item_quantity: this.dayCount,
          bill_category: 'debit',
          discount: 0,
        })
        .catch((err) => console.log(err));

      if (this.trxType == 'cash') {
        await api
          .post(`api/bills/`, {
            cost: this.amount_credited,
            trx_no: this.trans_id,
            special_discount: this.amountDiscount,
            special_discount_reason: this.specialDiscountReason,
            card_validity: this.doe_card,
            trx_type: 'cash',
            //
            type_bill: 'room',
            is_paid: false,
            bill_item: `Advance`,
            card_no: this.cardString,
            bill_category: 'credit',
          })
          .catch((err) => console.log(err));
      }
      if (this.trxType == 'card') {
        await api
          .post(`api/bills/`, {
            cost: this.amount_credited,
            trx_no: this.trans_id,
            special_discount: this.amountDiscount,
            special_discount_reason: this.specialDiscountReason,
            card_validity: this.doe_card,
            trx_type: 'card',
            //
            type_bill: 'room',
            is_paid: false,
            bill_item: `Advance`,
            card_no: this.cardString,
            bill_category: 'credit',
          })
          .catch((err) => console.log(err));
      }
      if (this.trxType == 'bkash') {
        await api
          .post(`api/bills/`, {
            cost: this.amount_credited,
            trx_no: this.trans_id,
            special_discount: this.amountDiscount,
            special_discount_reason: this.specialDiscountReason,
            card_validity: this.doe_card,
            trx_type: 'bkash',
            //
            type_bill: 'room',
            is_paid: false,
            bill_item: `Advance`,
            card_no: this.cardString,
            bill_category: 'credit',
          })
          .catch((err) => console.log(err));
      }
      await api
        .put(`api/room/${this.tempAdd.room_no}/`, {
          status_fd: 'occupied',
          room_no: this.tempAdd.room_no,
        })
        .catch((err) => console.log(err));

      await this.jeAddForDebit();
      await this.jeAddForCredit();

      //this.advanceScreen = false

      window.location.reload();
    },
    async jeAddForDebit() {
      let dataTT = [];
      let dataCAO = [];
      let user = [];
      console.log(user);
      // await axios
      //     .get(`auth/currentuser/`)
      //     .then(response => {
      //         user = response.data
      //     })
      //     .catch(error => {
      //             console.log(error)
      //         })
      await api
        .get('api/Transaction_table2/')
        .then((response) => {
          dataTT = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get('api/coa/')
        .then((response) => {
          dataCAO = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      let c = 1;
      if (!dataTT.length == 0) {
        c = parseInt(dataTT[dataTT.length - 1].journal_id) + 1;
      }
      let q = 0;
      for (let i = 0; i < dataCAO.length; i++) {
        if (dataCAO[i].cost_center === 'room_rent' && dataCAO[i].type === 'Debit') {
          console.log(dataCAO[i].id);
          q = dataCAO[i].id;
        }
      }
      const a = {
        description: 'Room Rent', //change
        date: new Date().toISOString().substr(0, 10),
        account_id: q,
        credited_by: 'frontdesk',
        reference: this.cardString, //change
        credit: 0, //change
        debit: parseInt(this.roomCost + this.roomCost * (this.companyInfo.tax / 100) + this.roomCost * (this.companyInfo.service_charge / 100)), //change
        memo: 'R' + this.tempAdd.room_no, //change
        journal_id: c,
        auto_journal: true,
      };
      await api.post('api/Transaction_table/', a).catch((err) => {
        console.log(err);
      });
    },
    async jeAddForCredit() {
      let dataTT = [];
      let dataCAO = [];
      let user = [];
      console.log(user);
      // await axios
      //     .get(`auth/currentuser/`)
      //     .then(response => {
      //         user = response.data
      //     })
      //     .catch(error => {
      //             console.log(error)
      //         })
      await api
        .get('api/Transaction_table2/')
        .then((response) => {
          dataTT = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get('api/coa/')
        .then((response) => {
          dataCAO = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      let c = 1;
      if (!dataTT.length == 0) {
        c = parseInt(dataTT[dataTT.length - 1].journal_id) + 1;
      }
      let q = 0;
      for (let i = 0; i < dataCAO.length; i++) {
        if (dataCAO[i].cost_center === 'advance' && dataCAO[i].type === 'Credit') {
          console.log(dataCAO[i].id);
          q = dataCAO[i].id;
        }
      }
      const a = {
        description: 'Advance', //change
        date: new Date().toISOString().substr(0, 10),
        account_id: q,
        credited_by: 'Frontdesk',
        reference: this.cardString, //change
        credit: this.amount_credited, //change
        debit: 0, //change
        memo: 'R' + this.tempAdd.room_no, //change
        journal_id: c,
        auto_journal: true,
      };
      await api.post('api/Transaction_table/', a).catch((err) => {
        console.log(err);
      });
    },
    async bellboySubmit() {
      console.log(this.resInfo[0]);
      await api
        .post('api/bellboy/', {
          check: false,
          status: false,
          room_no: this.resInfo[0].room,
          room_secondary: this.resInfo[0].secondary_room,
          special_ins: this.specialIns,
        })
        .then(
          toast({
            message: 'bellboy request has been submitted',
            type: 'is-info',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          })
        )
        .catch((err) => console.log(err));
    },
    async getRoomReport() {
      this.roomStatus = [0, 0, 0, 0];
      await api.get('api/room/').then((res) => {
        this.roomRepData = res.data;
      });
      this.roomView = this.roomRepData;
      for (let i = 0; i < this.roomRepData.length; i++) {
        if (this.roomRepData[i].status_fd == 'occupied') {
          this.roomStatus[0]++;
        }
        if (this.roomRepData[i].status_fd == 'available') {
          this.roomStatus[1]++;
        }
        if (this.roomRepData[i].status_fd == 'maintenance') {
          this.roomStatus[2]++;
        }
        if (this.roomRepData[i].status_fd == 'urgent') {
          this.roomStatus[3]++;
        }
      }
      console.log(this.roomStatus);
    },
    async billPayModal() {
      this.roomCost = 0;
      this.totalCalculated = 0;
      console.log(this.tempAdd);
      await api
        .get(`api/room_type/?type_name=${this.tempAdd.room_type}`)
        .then((res) => {
          this.selectedRoomTypeData = res.data[0];
          console.log(this.selectedRoomTypeData);
        })
        .catch((err) => console.log(err));
      this.advanceScreen = true;
      this.rejectModal = false;
      this.dayCount = (new Date(this.checkOutDate) - new Date(this.checkInDate)) / (1000 * 3600 * 24);
      console.log('dayss', this.dayCount);

      this.roomCost = parseInt(this.selectedRoomTypeData.cost) * this.dayCount - parseInt(this.selectedRoomTypeData.cost) * this.dayCount * (this.selectedRoomTypeData.discount / 100);
      this.totalCalculated = this.roomCost * (this.companyInfo.tax / 100) + this.roomCost * (this.companyInfo.service_charge / 100) + this.roomCost;
      console.log('c', this.checkInDate);
      console.log('o', this.checkOutDate);
    },
  },
};
</script>

<style scoped>
.tooltip {
  position: relative;
  display: inline-block;
  /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: white;
  color: black;
  text-align: center;
  margin-top: 30px;
  padding: 5px 0;
  border-radius: 6px;
  border: 1px solid black;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.editbutton:hover {
  transform: scale(1.1);
}

.home {
  height: 100vh;
}

ul li {
  float: left;
}
.occupied {
  background-color: #eb9683;
}
.available {
  background-color: #b4dbc8;
}
.maintenance {
  background-color: #add8e6;
}

.single {
  background-color: #87ff2a;
}

.double {
  background-color: #652dc1;
}

.delux {
  background-color: #ffeb00;
}
.tab-contents .content {
  display: none;
}
.tab-contents .content.is-active {
  display: block;
}

.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  width: 120px;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
