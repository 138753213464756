import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'




function guardMyroute(to, from, next)
{
 var isAuthenticated= false;
//this is just an example. You will have to find a better or 
// centralised way to handle you localstorage data handling 
if(localStorage.getItem('username'))
  isAuthenticated = true;
 else
  isAuthenticated= false;
 if(isAuthenticated) 
 {
  next(); // allow to enter route
 } 
 else
 {
  next('/login'); // go to '/login';
 }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    beforeEnter : guardMyroute,
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/register.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/DashBoard.vue')
  },
  {
    path: '/reservation',
    name: 'Reservation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Fd_Reservation.vue')
  },
  
  {
    path: '/hk_incident',
    name: 'HK Incident',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Fd_HouseKeepingIncident.vue')
  },
  {
    path:'/reservation/edit/:id',
    name: 'ReservationEdit',
    component: () => import( '../views/fd_ReservationEdit.vue')

  },
  {
    path:'/checkout/:id',
    name: 'checkout',
    component: () => import( '../views/Fd_checkout.vue'),
    props: true,

  },
  {
    path: '/lost&found',
    name: 'Lost And Found',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Fd_LostAndFound.vue')
  },
  {
    path: '/laundry',
    name: 'Laundry',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Hk_Laundry.vue')
  },
  {
    path: '/lost_found',
    name: 'LostFound',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Hk_LostFound.vue')
  },
  {
    path: '/hk_home',
    name: 'HkHome',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Hk_Home.vue')
  },
  {
    path: '/hk_room-service',
    name: 'RoomService',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/HK_RoomService.vue')
  },
  {
    path: '/bb_store-luggage',
    name: 'StoreLuggage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Bb_StoreLuggage.vue')
  },
  {
    path: '/bb_bell-boy',
    name: 'BellBoy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Bb_BellBoy.vue')
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Hk_Maintenance.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Settings.vue')
  },
  {
    path: '/settings_room',
    name: 'SettingsRoom',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Settings_Room.vue')
  },
  {
    path: '/settings_pic',
    name: 'SettingsPic',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Settings_Pic.vue')
  },
  {
    path: '/settings_item',
    name: 'SettingsItem',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Settings_Item.vue')
  },
  {
    path: '/settings_food',
    name: 'SettingsFood',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Settings_Food.vue')
  },
  {
    path: '/settings_laundry',
    name: 'SettingsLaundry',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Settings_Laundry.vue')
  },
  {
    path: '/stock',
    name: 'Stock',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Hk_Stock.vue')
  },
  {
    path: '/transaction_table',
    name: 'TransactionTable',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Gl_TransactionTable.vue')
  },
  {
    path: '/transaction_table_vat',
    name: 'TransactionTableVat',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Gl_TransactionTableVat.vue')
  },
  {
    path: '/transaction_table_auto',
    name: 'TransactionTableAuto',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Gl_TransactionTableAuto.vue')
  },
  {
    path: '/consierge',
    name: 'Consierge',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Fd_Consierge.vue')
  },
  {
    path: '/workflow',
    name: 'Workflow',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Workflow.vue')
  },
  {
    path: '/report',
    name: 'Report',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Report.vue')
  },
{
    path: '/report_guest',
    name: 'ReportGuest',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Report_Guest.vue')
  },
  {
    path: '/report_reservation',
    name: 'ReportReservation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Report_Reservation.vue')
  },
  {
    path: '/report_advance',
    name: 'ReportAdvance',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Report_Advance.vue')
  },
  {
    path: '/report_current_bill',
    name: 'ReportCurrentBill',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Report_Current_Bill.vue')
  },
  {
    path: '/report_pre_bill',
    name: 'ReportPreBill',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Report_Pre_Bill.vue')
  },
  {
    path: '/report_cash',
    name: 'ReportCash',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Report_Cash.vue')
  },
  {
    path: '/report_extraa',
    name: 'ReportExtraa',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Report_Extraa.vue')
  },
  {
    path: '/report_room_t',
    name: 'ReportRoomT',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Report_RoomT.vue')
  },
  {
    path: '/report_night',
    name: 'ReportNight',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Report_Night.vue')
  },
  {
    path: '/report_Daily',
    name: 'ReportDaily',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Report_Daily.vue')
  },
  {
    path: '/report_Monthly',
    name: 'ReportMonthly',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Report_Monthly.vue')
  },
  {
    path: '/report_demo',
    name: 'ReportDemo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Report_Demo.vue')
  },
  {
    path: '/online_booking',
    name: 'Online Booking',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Online_Booking.vue')
  },
  {
    path: '/pos_order',
    name: 'POSOrder',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pos_Order.vue')
  },
  {
    path: '/pos_kitchen',
    name: 'PosKitchen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pos_Kitchen.vue')
  },
  {
    path: '/purchase_req',
    name: 'PurchaseRequest',
    component: () => import(/* webpackChunkName: "about" */ '../views/PurchaseRequest.vue'),
    // beforeEnter : guardMyroute,
    
  },
  {
    path: '/purchase_table',
    name: 'PurchaseTable',
    component: () => import(/* webpackChunkName: "about" */ '../views/PurchaseTable.vue'),
    // beforeEnter : guardMyroute,
    
  },
  {
    path: '/rfq/:id',
    name: 'RFQ',
    component: () => import(/* webpackChunkName: "about" */ '../views/RFQ.vue'),
    // beforeEnter : guardMyroute,
    
  },
  {
    path: '/rfq_table',
    name: 'RFQTable',
    component: () => import(/* webpackChunkName: "about" */ '../views/RFQTable.vue'),
    // beforeEnter : guardMyroute,
    
  },
  {
    path: '/purchase_com_entry/:id',
    name: 'PurchaseComparisoneEntry',
    component: () => import(/* webpackChunkName: "about" */ '../views/PurchaseComparisoneEntry.vue'),
    // beforeEnter : guardMyroute,
  },
  {
    path: '/purchase_com_select/:id',
    name: 'PurchaseComparisonSelect',
    component: () => import(/* webpackChunkName: "about" */ '../views/PurchaseComparisonSelect.vue'),
    // beforeEnter : guardMyroute,
  },
  {
    path: '/purchase_order/:id',
    name: 'PurchaseOrder',
    component: () => import(/* webpackChunkName: "about" */ '../views/PurchaseOrder.vue'),
    // beforeEnter : guardMyroute,
  },
  {
    path: '/purchase_pre_grn/:id',
    name: 'PurchasePreGRN',
    component: () => import(/* webpackChunkName: "about" */ '../views/PurchasePreGRN.vue'),
    // beforeEnter : guardMyroute,
  },
  {
    path: '/inventory',
    name: 'Inventory',
    component: () => import(/* webpackChunkName: "about" */ '../views/Inventory.vue'),
    // beforeEnter : guardMyroute,
  },
  {
    path: '/inventory_issue',
    name: 'InventoryIssue',
    component: () => import(/* webpackChunkName: "about" */ '../views/InventoryIssue.vue'),
    // beforeEnter : guardMyroute,
  },
  {
    path: '/intentory_issue_table',
    name: 'IntentoryIssueTable',
    component: () => import(/* webpackChunkName: "about" */ '../views/IntentoryIssueTable.vue'),
    // beforeEnter : guardMyroute,
  },
  {
    path: '/lol2',
    name: 'text',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/text.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
