<script>
import Sidebar from '@/components/sidebar/Sidebar';
import { sidebarWidth } from '@/components/sidebar/state';
export default {
  components: { Sidebar },
  data() {
    return {};
  },
  created() {},
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

  setup() {
    return { sidebarWidth };
  },
};
</script>
<template>
  <div>
    <div v-if="currentRouteName != 'login' && currentRouteName != 'POSOrder' && currentRouteName != 'PosKitchen'">
      <Sidebar />
      <div :style="{ 'margin-left': sidebarWidth }">
        <router-view />
      </div>
    </div>
    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<style lang="scss">
@import '../node_modules/bulma';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f6f7fb;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
