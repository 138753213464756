<script>
import api from '../../boot/axios';
import SidebarLink from './SidebarLink';
import { collapsed, toggleSidebar, sidebarWidth } from './state';
//import { getAuthAPI } from "../../../axios-api"

import axios from '../../boot/axios';

export default {
  props: {},
  components: { SidebarLink },
  data() {
    return {
      userRole: '',
      hotel: '',
      logo: null,
      userNameSidebar: '',
      userData: '',
    };
  },
  setup() {
    return { collapsed, toggleSidebar, sidebarWidth };
  },
  created() {
    this.userNameSidebar = localStorage.getItem('username');
    this.getUserType();
    this.getHotel();
    this.getLogo();
  },
  methods: {
    async logout() {
      this.userNameSidebar = null;
      let r_token = localStorage.getItem('r_token');
      localStorage.removeItem('jwt');
      localStorage.removeItem('r_token');
      localStorage.removeItem('username');

      await api.post('/auth/logout', { r_token: r_token }, { withCredentials: true });
      api.defaults.headers.common['Authorization'] = '';
      this.indx++;
      this.$router.push('/login');
    },
    // async getUserType(){
    //     await getAuthAPI.get('currentuser/'
    //         ).then(res =>{
    //             console.log(res.data)
    //             this.userType = res.data.role
    //         }).catch(err => console.log(err))
    // }
    async getUserType() {
      await axios
        .get('/auth/currentuser/')
        .then((res) => {
          this.userRole = res.data.role;
          this.userData = res.data;
        })
        .catch((err) => console.log(err));
    },
    async getHotel() {
      await axios
        .get('/auth/company/1/')
        .then((res) => {
          this.hotel = res.data;
        })
        .catch((err) => console.log(err));
    },

    async getLogo() {
      await axios
        .get('/auth/hotel_logo/1/')
        .then((res) => {
          this.logo = res.data.pic;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<template>
  <!-- color:white; filter: brightness(0) invert(1); -->
  <div class="sidebar" :style="{ width: sidebarWidth }" style="">
    <div style="display: flex">
      <div style=""><img :src="logo" alt="Hotel" style="max-width: 50px; left: 0" /></div>
      <div style="margin-left: 10%, margin-top: 6px">
        <p style="margin: 0px 0px 0px 0px; font-size: 22px; font-weight: 540">{{ hotel.company_name }}</p>
      </div>
    </div>

    <div v-if="userRole === 1">
      <SidebarLink class="ab" to="/" icon="fas fa-home" style="margin-top: 40px; font-size: 20px">Home</SidebarLink>
      <!-- <SidebarLink to="/dashboard" icon="fas fa-laptop" style="font-size: 20px; margin-top: 20px">Dashboard</SidebarLink> -->
      <SidebarLink to="/reservation" icon="fas fa-laptop" style="font-size: 20px; margin-top: 20px">Reservation</SidebarLink>
      <!-- <SidebarLink to="/reports" icon="fas fa-chart-bar" style="font-size:20px; margin-top:20px;">Reports</SidebarLink> -->
      <SidebarLink to="/report" icon="fas fa-solid fa-cubes" style="font-size: 20px; margin-top: 20px">Report</SidebarLink>

      <SidebarLink to="/consierge" icon="fas fa-bed" style="font-size: 20px; margin-top: 20px">Concierge</SidebarLink>
      <!-- <SidebarLink to="/settings" icon="fa-solid fa-gear" style="font-size:20px; margin-top:20px;">Settings</SidebarLink> -->
      <SidebarLink to="/lost&Found" icon="fas fa-solid fa-box" style="font-size: 20px; margin-top: 20px">LostFound</SidebarLink>
      <SidebarLink to="/hk_incident" icon="fas fa-bed" style="font-size: 20px; margin-top: 20px">ComplainRecord</SidebarLink>
    </div>
    <div v-if="userRole === 2">
      <SidebarLink to="/hk_home" icon="fas fa-home" style="font-size: 20px; margin-top: 20px">Home</SidebarLink>
      <SidebarLink to="/laundry" icon="fas fa-solid fa-mitten" style="font-size: 20px; margin-top: 20px">laundry</SidebarLink>
      <SidebarLink to="/lost_found" icon="fas fa-solid fa-box" style="font-size: 20px; margin-top: 20px">LostFound HK</SidebarLink>
      <SidebarLink to="/maintenance" icon="fas fa-solid fa-wrench" style="font-size: 20px; margin-top: 20px">Maintenance</SidebarLink>
      <SidebarLink to="/stock" icon="fas fa-solid fa-cubes" style="font-size: 20px; margin-top: 20px">Stock</SidebarLink>

      <SidebarLink to="/transaction_table" icon="fas fa-solid fa-coins" style="font-size: 20px; margin-top: 20px">Transaction</SidebarLink>
      <!-- <SidebarLink to="/transaction_table_auto" icon="fas fa-solid fa-coins" style="font-size:20px; margin-top:20px;">Transaction(Auto)</SidebarLink> -->
    </div>
    <div v-if="userRole === 4">
      <SidebarLink to="/bb_bell-boy" icon="fas fa-solid fa-bell" style="font-size: 20px; margin-top: 20px">Bell Boy</SidebarLink>
      <SidebarLink to="/bb_store-luggage" icon="fas fa-solid fa-bell" style="font-size: 20px; margin-top: 20px">Luggage</SidebarLink>
    </div>
    <div v-if="userRole === 5">
      <SidebarLink to="/hk_room-service" icon="fas fa-solid fa-utensils" style="font-size: 20px; margin-top: 20px">Room Service</SidebarLink>
    </div>
    <div v-if="userRole === 6">
      <SidebarLink to="/stock" icon="fas fa-solid fa-cubes" style="font-size: 20px; margin-top: 20px">Stock</SidebarLink>

      <SidebarLink to="/transaction_table" icon="fas fa-solid fa-coins" style="font-size: 20px; margin-top: 20px">Transaction</SidebarLink>
      <SidebarLink to="/transaction_table_auto" icon="fas fa-solid fa-coins" style="font-size: 20px; margin-top: 20px">VAT Ledger</SidebarLink>
      <SidebarLink to="/purchase_req" icon="fas fa-solid fa-hammer" style="font-size: 20px; margin-top: 20px">Purchase Req</SidebarLink>
      <SidebarLink to="/purchase_table" icon="fas fa-solid fa-hammer" style="font-size: 20px; margin-top: 20px">Purchase Table</SidebarLink>
      <SidebarLink to="/rfq_table" icon="fas fa-solid fa-hammer" style="font-size: 20px; margin-top: 20px">RFQ Table</SidebarLink>

      <SidebarLink to="/inventory" icon="fas fa-solid fa-hammer" style="font-size: 20px; margin-top: 20px">Inventory</SidebarLink>
      <SidebarLink to="/inventory_issue" icon="fas fa-solid fa-hammer" style="font-size: 20px; margin-top: 20px">Inventory Issue</SidebarLink>
      <SidebarLink to="/intentory_issue_table" icon="fas fa-solid fa-hammer" style="font-size: 20px; margin-top: 20px">Issue Table</SidebarLink>
    </div>
    <div v-if="userRole === 7">
      <SidebarLink to="/workflow" icon="fas fa-solid fa-box" style="font-size: 20px; margin-top: 20px">Workflow</SidebarLink>
      <SidebarLink to="/settings" icon="fa-solid fa-gear" style="font-size: 20px; margin-top: 20px">Settings</SidebarLink>
    </div>

    <div v-if="userRole === 3" style="height: 77vh; margin-top: 10px">
      <SidebarLink class="ab" to="/" icon="fas fa-home" style="margin-top: 40px; font-size: 20px">Home</SidebarLink>
      <!-- <SidebarLink to="/dashboard" icon="fas fa-solid fa-hammer" style="font-size:20px; margin-top:20px;">Dashboard</SidebarLink> -->
      <SidebarLink to="/reservation" icon="fas fa-laptop" style="font-size: 20px; margin-top: 20px">Reservation</SidebarLink>

      <SidebarLink to="/hk_home" icon="fas fa-home" style="font-size: 20px; margin-top: 20px">Home(HK)</SidebarLink>
      <SidebarLink to="/laundry" icon="fas fa-solid fa-mitten" style="font-size: 20px; margin-top: 20px">laundry</SidebarLink>
      <SidebarLink to="/maintenance" icon="fas fa-solid fa-wrench" style="font-size: 20px; margin-top: 20px">Maintenance</SidebarLink>
      <SidebarLink to="/transaction_table" icon="fas fa-solid fa-coins" style="font-size: 20px; margin-top: 20px">Transaction</SidebarLink>
      <!-- <SidebarLink to="/transaction_table_auto" icon="fas fa-solid fa-coins" style="font-size:20px; margin-top:20px;">Transaction(Auto)</SidebarLink> -->

      <SidebarLink to="/hk_room-service" icon="fas fa-solid fa-utensils" style="font-size: 20px; margin-top: 20px">Room Service</SidebarLink>
      <SidebarLink to="/stock" icon="fas fa-solid fa-cubes" style="font-size: 20px; margin-top: 20px">Stock</SidebarLink>
      <!-- <SidebarLink to="/transaction_table" icon="fas fa-solid fa-coins" style="font-size:20px; margin-top:20px;">Transaction</SidebarLink> -->

      <SidebarLink to="/workflow" icon="fas fa-solid fa-box" style="font-size: 20px; margin-top: 20px">Workflow</SidebarLink>
      <SidebarLink to="/purchase_req" icon="fas fa-solid fa-hammer" style="font-size: 20px; margin-top: 20px">Purchase Req</SidebarLink>
      <SidebarLink to="/purchase_table" icon="fas fa-solid fa-hammer" style="font-size: 20px; margin-top: 20px">Purchase Table</SidebarLink>
      <SidebarLink to="/rfq_table" icon="fas fa-solid fa-hammer" style="font-size: 20px; margin-top: 20px">RFQ Table</SidebarLink>
      <SidebarLink to="/inventory" icon="fas fa-solid fa-hammer" style="font-size: 20px; margin-top: 20px">Inventory</SidebarLink>
      <SidebarLink to="/inventory_issue" icon="fas fa-solid fa-hammer" style="font-size: 20px; margin-top: 20px">Inventory Issue</SidebarLink>
      <SidebarLink to="/intentory_issue_table" icon="fas fa-solid fa-hammer" style="font-size: 20px; margin-top: 20px">Issue Table</SidebarLink>
      <SidebarLink to="/settings" icon="fas fa-solid fa-hammer" style="font-size: 20px; margin-top: 20px">Settings</SidebarLink>
    </div>
    <div v-if="userRole === 8">
      <SidebarLink to="/pos_order" icon="fas fa-solid fa-border-none" style="font-size: 20px; margin-top: 20px">Order</SidebarLink>
      <SidebarLink to="/pos_kitchen" icon="fas fa-solid fa-utensils" style="font-size: 20px; margin-top: 20px">Kitchen</SidebarLink>
    </div>
    <div style="position: fixed; bottom: 25px; border-top: 1px solid #a4adba; width: 190px">
      <div style="float: left">
        <p style="font-size: 20px; color: #a4adba; font-weight: 700">{{ userData.username }}</p>
      </div>

      <div class="tooltip" style="float: right; margin-left: 3em">
        <span class="tooltiptext">Log Out</span>
        <!-- <a class="" @click="logout" style="font-size:22px; color:white;"><i class="fas fa-solid fa-arrow-right-from-bracket"></i></a> -->
        <a @click="logout" style=""><img class="logB" src="../../assets/logout_w.png" style="text-align: center; cursor: pointer" /></a>
      </div>
    </div>
    <div style="position: fixed; bottom: 8px; width: 190px">
      <div style="float: left">
        <p style="font-size: 18px; color: #a4adba; font-weight: 500">@{{ userData.position }}</p>
      </div>
    </div>
    <!-- reservation
    guest
    billing
    housekeeping 
    Reports 
    Lost And Found -->
    <!-- <span
      class="collapse-icon"
      :class="{ 'rotate-180': collapsed }"
      @click="toggleSidebar"
    >
      <i class="fas fa-angle-double-left" />
    </span> -->
  </div>
</template>

<style>
:root {
  --sidebar-bg-color: #51627e;
  --sidebar-item-hover: #485973;
  --sidebar-item-active: #425269;
}
</style>

<style scoped>
.sidebar {
  color: white;
  background-color: var(--sidebar-bg-color);

  float: left;
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 0.5em;

  transition: 0.3s ease;

  display: flex;
  flex-direction: column;
}

.sidebar h1 {
  height: 2.5em;
}

.collapse-icon {
  position: absolute;
  bottom: 0;
  padding: 0.75em;

  color: rgba(255, 255, 255, 0.7);

  transition: 0.2s linear;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: 0.2s linear;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  width: 120px;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.logB {
  width: 20px;
}
.logB:hover {
  outline: solid;
  outline-color: white;
  outline-offset: 2px;
}
</style>
